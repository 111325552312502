<template>
  <div class="flex items-center space-x-4">
    <p v-if="selectedOrg" class="text-xs text-white font-medium cursor-text">{{ selectedOrg }}</p>
    <el-select size="mini" :value="selectedOrg" @change="(value) => selectOrg(value)" filterable clearable>
      <el-option v-for="org in orgs" :key="org._id" :value="org._id" :label="`${org.name + ': ' || ''}${org._id}`">
        <div>
          <p class="float-left text-brand-blue text-xs pt-2">
            {{ org.name }}
          </p>
          <!-- <p class="float-right mr-4 text-gray-600 text-xs">{{ org._id }}</p> -->
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { IOrg } from '@/store/types';
import AdminService from '@/api/admin.service';
import * as mutations from '@/store/mutation-types';
import { SWITCH_ORG } from '@/store/action-types';

export default Vue.extend({
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    const orgs = await AdminService.listOrgs();
    this.$store.commit(mutations.SET_ORGS, orgs);
    this.loading = false;
  },
  computed: {
    orgs(): IOrg[] {
      return this.$store.state.orgs;
    },
    selectedOrg() {
      return this.$store.state.asOrg;
    },
    selectedOrgName(): string {
      return this.orgs.find((d) => d._id === this.selectedOrg)?.name || this.selectedOrg;
    },
  },
  methods: {
    selectOrg(value: string | null | undefined) {
      this.$store.commit(mutations.ADMIN_CLEAR_STATE);
      this.$store.dispatch(SWITCH_ORG, value || null);
    },
  },
});
</script>
