import { IDomainDetails, IOrg } from '@/store/types';
import Axios from '@/helpers/axios-wrapper';
import CampaignService from './campaign.service';

const RESOURCE = '/api/dunning';

export default {
  /**
   * @param {String} domainId the doc's mongo id
   */
  async getSenderDomain(domainId: string): Promise<IDomainDetails> {
    const response = await Axios.get<IDomainDetails>(`${RESOURCE}/domains/sender/${domainId}`);
    return response;
  },

  /**
   * @param {Object} dunning
   * @param {Object} dunning domain url and return path
   */
  addSenderDomain(dunning: { senderDomain: string; returnPath: string }): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/domains/sender`, dunning);
  },

  /**
   * @param {String} domainId the doc's mongo id
   * @param {Object} dunning domain url and return path
   */
  updateSenderDomain(domainId: string, dunning: { senderDomain: string; returnPath: string }): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/domains/sender/${domainId}`, dunning);
  },

  /**
   * @param {String} domainId the doc's mongo id
   */
  deleteSenderDomain(domainId: string): Promise<void> {
    return Axios.delete(`${RESOURCE}/domains/sender/${domainId}`);
  },

  /**
   * @param {String} domainId the doc's mongo id
   */
  verifyDkim(domainId: string): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/domains/sender/${domainId}/verify`);
  },

  /**
   * @param {Object} containing ckSubdomain: string, customDomain: string, redirectLink: string
   */
  configureCheckout(checkoutConfig: { customDomain?: string; ckSubdomain?: string; redirectLink?: string }): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/domains/custom`, checkoutConfig);
  },

  /**
   * @param {String} domainId the custom domains identifier
   */
  getCustomDomain(domainId: string): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/domains/custom/${domainId}`);
  },

  /**
   * @param {String} domainId the custom domains identifier
   */
  deleteCustomDomain(domainId: string): Promise<void> {
    return Axios.delete(`${RESOURCE}/domains/custom/${domainId}`);
  },

  /**
   * @param {String} domainId
   */
  setPrimary(domainId: string): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/domains/custom/${domainId}/primary`);
  },

  ...CampaignService(RESOURCE),
};
