import { IAIJob } from '@/store/types';
import { AI_JOB_STATUS } from '@/store/shared';

interface AIJobMatchParams {
  blueprintGuid?: string;
  stepGuid?: string;
  surveyChoiceGuid?: string;
}

// It's a step-specific job
export function generatingStep(job: IAIJob, blueprintGuid: string, stepGuid: string): boolean {
  return (job.status === AI_JOB_STATUS.RUNNING && job.blueprintGuid === blueprintGuid
    && job.stepGuid === stepGuid)
    && !job.surveyChoiceGuid;
}

// It's a whole-blueprint job
export const generatingBlueprint = (job: IAIJob, blueprintGuid: string): boolean => {
  return (job.status === AI_JOB_STATUS.RUNNING && job.blueprintGuid === blueprintGuid)
    && !job.stepGuid && !job.surveyChoiceGuid;
};

// It's a survey-choice-specific job
export const generatingChoice = (job: IAIJob, blueprintGuid: string, surveyChoiceGuid: string): boolean => {
  return (job.status === AI_JOB_STATUS.RUNNING && job.blueprintGuid === blueprintGuid)
    && job.surveyChoiceGuid === surveyChoiceGuid;
};

// It's either a step-specific or whole-blueprint job
export function jobAffectsStep(job: IAIJob, blueprintGuid: string, stepGuid: string): boolean {
  return generatingStep(job, blueprintGuid, stepGuid) || generatingBlueprint(job, blueprintGuid);
}

// It's either a survey-choice-specific or whole-blueprint job
export function jobAffectsSurveyChoice(job: IAIJob, blueprintGuid: string, surveyChoiceGuid: string): boolean {
  return generatingChoice(job, blueprintGuid, surveyChoiceGuid) || generatingBlueprint(job, blueprintGuid);
}

// It's a whole-blueprint job
export function jobAffectsBlueprint(job: IAIJob, blueprintGuid: string): boolean {
  return generatingBlueprint(job, blueprintGuid);
}

// Match a job to a specific blueprint, step, or survey choice
export function jobMatch(job: IAIJob, { blueprintGuid, stepGuid, surveyChoiceGuid }: AIJobMatchParams): boolean {
  if (blueprintGuid && stepGuid) return generatingStep(job, blueprintGuid, stepGuid);
  if (blueprintGuid && surveyChoiceGuid) return generatingChoice(job, blueprintGuid, surveyChoiceGuid);
  if (blueprintGuid) return generatingBlueprint(job, blueprintGuid);
  return false;
}

// A Step or Offer AI Job is running for this blueprintGuid
// Used to disable the Blueprint Build/Rework button while a child job is running.
export function isChild(job: IAIJob, blueprintGuid: string): boolean {
  const child = !!(job.stepGuid || job.surveyChoiceGuid);
  const running = (job.status === AI_JOB_STATUS.RUNNING);
  const bp = (job.blueprintGuid === blueprintGuid);
  return child && running && bp;
}
