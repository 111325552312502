<template>
  <header class="header_style" v-if="depth > 1 && availableSiblings.length > 1">
    <nav class="flex text-brand-brown font-semibold text-sm space-x-4 lg:space-x-8 justify-start pl-1">
      <router-link
        v-for="route in availableSiblings"
        :key="routeLabel(route)"
        :to="linkTarget(route)"
        class="pb-1 transition-colors font-semibold"
        active-class="text-brand-blue border-b-2 border-brand-blue"
        :class="{ capitalize: !hasLabel(route) }"
      >
        {{ routeLabel(route) }}
      </router-link>
    </nav>
  </header>
</template>

<script lang="ts">
import { siblingPath, siblingRoutes } from '@/helpers/routing';
import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import { mapGetters } from 'vuex';

// This component will render all sibling routes in a tab nav.
// - To set tab labels, add a `meta.label` property to the sibling route.
// - To hide a sibling route's tab, add a `meta.hidden = true` property to the sibling route.
// - Unauthorized routes will be hidden according to the Vuex state.
export default Vue.extend({
  name: 'TabbedNav',
  computed: {
    ...mapGetters(['isAuthorizedRoute', 'routeLabel']),
    availableSiblings(): RouteConfig[] {
      return siblingRoutes(this.$route).filter((r) => {
        if (r.meta?.hidden) return false;
        if (r.redirect) return false;
        if (!this.isAuthorizedRoute(r)) return false;

        // Ignore targets that include params
        if (r.path.match(/\/:/)) return false;

        return true;
      });
    },
    depth() {
      return this.$route.matched.length;
    },
  },
  methods: {
    isCurrent(route: RouteConfig) {
      return route.path === this.$route.path;
    },
    hasLabel(route: RouteConfig): boolean {
      return !!route.meta?.label;
    },
    linkTarget(route: RouteConfig): string {
      return siblingPath(route, this.$route);
    },
  },
});
</script>

<style scoped>
.header_style {
  position: relative;
  z-index: 1;
  width: 80%;
}
</style>
