import AIService from '@/api/ai.service';
import { jobMatch } from '@/helpers/ai-jobs';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import { IState, IAIJob } from '../types';
import { AI_JOB_STATUS } from '../shared';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const aiModule = {
  state: {
    aiJobs: [],
  },
  actions: {
    // load jobs for current org
    async [ACT.AI_LOAD_JOBS]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        const { jobs } = await AIService.loadJobs();
        commit(MUTATE.SET_AI_JOBS, jobs);
      } catch (e) {
        console.log('Error loading AI Jobs', e);
      }
    },
    // mark a job as completed
    async [ACT.AI_COMPLETE_JOB]({ state, commit, dispatch }: IActionContext, id: string) {
      try {
        const job = await AIService.complete(id);
        commit(MUTATE.UPDATE_AI_JOB, job);
      } catch (e) {
        console.log('Error completing AI job', e);
      }
    },
    // cancel job
    async [ACT.AI_CANCEL_JOB]({ state, commit, dispatch }: IActionContext, options: any) {
      const { blueprintGuid, stepGuid, surveyChoiceGuid } = options;
      const jobToCancel = state.org?.aiJobs?.find((j: IAIJob) => jobMatch(j, {
        blueprintGuid,
        stepGuid,
        surveyChoiceGuid,
      }));
      if (!jobToCancel) return;
      try {
        const updatedJob = await AIService.cancel(jobToCancel?._id);
        commit(MUTATE.UPDATE_AI_JOB, updatedJob);
      } catch (e) {
        console.log('Error completing AI job', e);
      }
    },
    async [ACT.AI_GENERATE_BLUEPRINT]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        const job = await AIService.generate(options);
        commit(MUTATE.ADD_AI_JOB, job);
      } catch (e) {
        console.log('Error generating AI blueprint', e);
      }
    },
    async [ACT.AI_REWORK_BLUEPRINT]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        const job = await AIService.rework(options);
        commit(MUTATE.ADD_AI_JOB, job);
      } catch (e) {
        console.log('Error reworking AI blueprint', e);
      }
    },
    async [ACT.AI_REWORK_STEP]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        const job = await AIService.rework(options);
        commit(MUTATE.ADD_AI_JOB, job);
      } catch (e) {
        console.log('Error reworking AI step', e);
      }
    },
    async [ACT.AI_REWORK_SURVEY_CHOICE]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        const job = await AIService.rework(options);
        commit(MUTATE.ADD_AI_JOB, job);
      } catch (e) {
        console.log('Error reworking AI offer', e);
      }
    },
  },
  mutations: {
    [MUTATE.SET_AI_JOBS](state: IState, jobs: IAIJob[]) {
      if (state.org) {
        state.org.aiJobs = jobs;
      }
    },
    [MUTATE.ADD_AI_JOB](state: IState, job: IAIJob) {
      if (state.org) {
        if (!state.org.aiJobs) state.org.aiJobs = [];
        state.org.aiJobs.push(job);
      }
    },
    [MUTATE.UPDATE_AI_JOB](state: IState, job: IAIJob) {
      if (state.org) {
        if (!state.org.aiJobs) state.org.aiJobs = [];
        const index = state.org.aiJobs.findIndex((j: IAIJob) => j._id === job._id);
        if (index > -1) {
          state.org.aiJobs.splice(index, 1, job);
        }
      }
    },
  },
  getters: {
    aiJobsInProgress: (state: IState) => {
      return (state.org?.aiJobs || []).filter((job: IAIJob) => job.status === AI_JOB_STATUS.RUNNING);
    },
  },
};

export default aiModule;
