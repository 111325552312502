import { IOrg } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/a';

export default {
  listOrgs(): IOrg[] {
    return Axios.get(`${RESOURCE}/o`);
  },
  listIssues(): any[] {
    return Axios.get(`${RESOURCE}/issues`);
  },
  materializedRevenue(): any[] {
    return Axios.get(`${RESOURCE}/real-revenue`);
  },
  adminSetOrgSetting(orgId: string, setting: string, value: boolean): any {
    return Axios.post(`${RESOURCE}/update-org-setting`, { orgId, setting, value });
  },
};
