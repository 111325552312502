import { format } from 'd3';
import Vue from 'vue';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line arrow-body-style
export const resolvePath = (object: Record<string, any>, path: string, defaultValue: any = null) => {
  return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
};

export const capitalize = (s: string) => {
  return s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
Vue.filter('capitalize', capitalize);

/**
 * Creates an object composed of the picked object properties.
 *
 * var object = {'a': 1, 'b': '2', 'c': 3};
 * pick(object, ['a', 'c']);
 * // → {'a': 1, 'c': 3}
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const pickPaths = <T extends object, K extends keyof T>(obj: T, props: readonly string[]): Pick<T, K> => {
  const copy: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const prop of props) {
    copy[prop] = resolvePath(obj, prop);
  }
  return copy;
};

export const formatMoney = (amount: number | null | undefined) => {
  const formatter = format('$0.3s');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatMoney', formatMoney);

export const formatRawMoney = (amount: number | null | undefined) => {
  const formatter = format('$,.0f');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatRawMoney', formatRawMoney);

export const formatSmallMoney = (amount: number | null | undefined) => {
  if (amount && amount > 1000) {
    return formatMoney(amount);
  }
  const formatter = format('$0.2f');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatSmallMoney', formatSmallMoney);

export const formatCondensedMoney = (amount: number | null | undefined) => {
  if (amount && amount > 100) {
    return formatMoney(amount);
  }
  const formatter = format('$0.0f');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatCondensedMoney', formatCondensedMoney);

export const formatPercent = (amount: number | null | undefined, decimalPlaces = 0) => {
  const formatter = format(`.${typeof decimalPlaces === 'number' ? decimalPlaces : 0}%`);
  if (amount) {
    return `${formatter(amount)}`;
  }
  if (amount === 0) {
    return '0%';
  }
  return '-';
};
Vue.filter('formatPercent', formatPercent);

export const formatNumber = (amount: number | null | undefined) => {
  const formatter = format('.3s');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatNumber', formatNumber);

export const formatRawNumber = (amount: number | null | undefined) => {
  const formatter = format(',');
  if (amount) {
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatNumber', formatRawNumber);

export const formatSmallNumber = (amount: number | null | undefined) => {
  if (amount && amount >= 10000) {
    return formatNumber(amount);
  }
  if (amount && amount >= 1000) {
    const formatter = format(',.3~r');
    return `${formatter(amount)}`;
  }
  if (amount) {
    const formatter = format('.3~s');
    return `${formatter(amount)}`;
  }
  return '-';
};
Vue.filter('formatSmallNumber', formatSmallNumber);

// eslint-disable-next-line arrow-body-style
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isBlacklistedEmail = (email: string) => {
  const blacklist = ['gmail', 'hotmail', 'yahoo', 'aol.com', 'devandgo'];
  const blacklisted = blacklist.reduce((acc, domain) => acc || email.includes(domain), false);
  return blacklisted;
};

export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export function copyPropertiesIfExist(source: Record<string, any>, target: Record<string, any>, properties: string[]): Record<string, any> {
  return Object.assign(target, ...properties.map(prop => 
    Object.prototype.hasOwnProperty.call(source, prop) ? { [prop]: source[prop] } : {}
  ));
}