import { SAVE_TYPE, SUBSCRIPTION_INTERVAL } from '@/store/shared';
import { EmailStatus, IEmail, IFilterConfig, ICampaign, ISession } from '@/store/types';
import { ascending } from 'd3-array';
import { timeMonth, timeDay, timeHour, timeMinute } from 'd3-time';
import Vue from 'vue';
import { dateTimeLabelFormat } from './date-utils';
import { getCurrencyDivisor } from './currency-utils';
import { OfferType } from '@/models/Offer';

export const SESSION = {
  TRIAL: 'trial',
  MODE: 'mode',
  SEGMENT_ID: 'segmentId',
  RESPONSE: 'response',
  ABORTED: 'aborted',
  CANCELED: 'canceled',
  PAUSE_DURATION: 'pauseDuration',
  COUPON_ID: 'couponId',
  PLAN_ID: 'planId',
  BILLING_INTERVAL: 'billingInterval',
  OFFER_TYPE: 'offerType',
  SAVE_TYPE: 'saveType',
  DAY: 'day',
  MONTH: 'month',
  WEEK: 'week',
  INVOICE_MONTH: 'invoiceMonth',
  BOUNCED: 'bounced',
  AGE_MONTHS: 'ageMonths',
  AGE_QUARTERS: 'ageQuarters',
  AGE_YEARS: 'ageYears',
  ORG_ID: 'orgId',
  FEEDBACK: 'feedback',
  FEEDBACK_SCORES: 'feedbackScores',
  SENTIMENT_SCORES: 'sentimentScores',
};

// eslint-disable-next-line arrow-body-style
export const filterToQuery = (filter: Record<string, any>): string => {
  return Object.keys(filter)
    .map((k) => {
      if (['startDate', 'endDate'].includes(k)) {
        return `${k}=${filter[k].toISOString()}`;
      }
      // to map nonexistence in with false
      if (filter[k] === 'false' || filter[k] === false) {
        return `-${k}=true`;
      }
      return `${k}=${filter[k]}`;
    })
    .join('&');
};

export const filterBreakdownQuery = ({ filter, breakdown }: { filter?: Record<string, any>; breakdown?: string[] }) => {
  const filterQuery = filterToQuery(filter || {});
  const breakdownQuery = breakdown ? `&breakdown=${breakdown.join('-')}` : '';
  return `${filterQuery}${breakdownQuery}`;
};

export const sessionFilterOptions = [
  {
    value: SESSION.BILLING_INTERVAL,
    label: 'Billing Interval',
    options: [SUBSCRIPTION_INTERVAL.MONTH, SUBSCRIPTION_INTERVAL.YEAR],
  },
  {
    value: SESSION.PLAN_ID,
    label: 'Plan',
  },
];

const sessionDateFields = ['recordingStartTime', 'recordingEndTime', 'createdAt', 'updatedAt'];
const sessionCustomerDateFields = [
  'created',
  'subscriptionStart',
  'subscriptionPeriodStart',
  'subscriptionPeriodEnd',
  'trialStart',
  'trialEnd',
  'pauseEnd',
];

export const mapSessionDates = (rawSession: any) => {
  const d = { ...rawSession };
  sessionDateFields.forEach((f) => {
    if (d[f]) {
      d[f] = new Date(d[f]);
    }
    if (d.customer) {
      sessionCustomerDateFields.forEach((cf) => {
        if (d.customer[cf]) {
          d.customer[cf] = new Date(d.customer[cf]);
        }
      });
    }
  });
  return d;
};

export const processEventData = (rawEvent: any) => {
  const d = rawEvent;
  if (d.createdAt) {
    d.createdAt = new Date(d.createdAt);
  }
  if (d.updatedAt) {
    d.updatedAt = new Date(d.updatedAt);
  }
  return d;
};

export const processDataRecord = (rawData: any) => {
  const d = rawData;
  if (d.createdAt) {
    d.createdAt = new Date(d.createdAt);
  }
  if (d.updatedAt) {
    d.updatedAt = new Date(d.updatedAt);
  }
  if (d.dataUpdated) {
    Object.keys(d.dataUpdated).forEach((f) => {
      if (d.dataUpdated[f]) {
        d.dataUpdated[f] = new Date(d.dataUpdated[f]);
      }
    });
  } else {
    d.dataUpdated = {};
  }
  return d;
};

export const getSessionSubscriptionPrice = (session: ISession) => {
  const { customer } = session;
  if (!customer?.planPrice) {
    return '';
  }
  const divisor = getCurrencyDivisor(customer.currency);
  let amount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: customer.currency || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(customer.planPrice / divisor);
  // trim off currency trailing decimal zeros
  amount = amount.replace(/\.00$/, '');
  return amount;
};

Vue.filter('sessionSubscriptionPrice', getSessionSubscriptionPrice);

export const currencyFormat = (amount: number, currency: string) => {
  let newAmount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency.toLowerCase() || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(amount);
  // trim off currency trailing decimal zeros
  newAmount = newAmount.replace(/\.00$/, '');
  return newAmount;
};

export const currencyFormatWithDivisor = (amount: number, currency: string) => {
  const divisor = getCurrencyDivisor(currency);
  return currencyFormat(amount / divisor, currency);
};

Vue.filter('currencyFormat', currencyFormat);
Vue.filter('currencyFormatWithDivisor', currencyFormatWithDivisor);

export const processStatsData = (d: any) => {
  Object.keys(d).forEach((f) => {
    if (f === SESSION.MONTH) {
      // send date (d.sendDate) is formatted 2023-12-25 but is in UTC time
      // add difference to utc time to get local time
      const timezoneOffsetTime = timeMinute.offset(new Date(d.month), new Date().getTimezoneOffset());
      // daylight savings hack, rounding to month anyway
      const offsetTime = timeDay.offset(new Date(timezoneOffsetTime), 1);
      d.startDate = timeMonth.round(offsetTime);
      d.endDate = timeMonth.round(timeMonth.offset(d.startDate, 1));
    }
    if (f === SESSION.INVOICE_MONTH) {
      const timezoneOffsetTime = timeMinute.offset(new Date(d.invoiceMonth), new Date().getTimezoneOffset());
      // daylight savings hack, rounding to month anyway
      const offsetTime = timeDay.offset(new Date(timezoneOffsetTime), 1);
      d.invoiceStartDate = timeMonth.round(new Date(offsetTime));
      d.invoiceEndDate = timeMonth.round(timeMonth.offset(d.invoiceStartDate, 1));
    }
    if (f === 'boostedRevenue') {
      d[f] /= 100;
    }
  });
  if (d.boostedRevenueUsd) {
    d.boostedRevenue = d.boostedRevenueUsd;
  }
  return d;
};

const toString = (d: any) => d.toString();
const billingIntervalToLabel = {
  [SUBSCRIPTION_INTERVAL.WEEK]: 'Weekly',
  [SUBSCRIPTION_INTERVAL.MONTH]: 'Monthly',
  [SUBSCRIPTION_INTERVAL.YEAR]: 'Annual',
};
const offerTypeToLabel = {
  [OfferType.CONTACT]: 'Support',
  [OfferType.DISCOUNT]: 'Discount',
  [OfferType.PAUSE]: 'Pause',
  [OfferType.TRIAL_EXTENSION]: 'Trial Extension',
  [OfferType.PLAN_CHANGE]: 'Switch Plan',
  [OfferType.REDIRECT]: 'Redirect URL',
};
const saveTypeToLabel = {
  [SAVE_TYPE.CONTACT]: 'Support',
  [SAVE_TYPE.DISCOUNT]: 'Discount',
  [SAVE_TYPE.PAUSE]: 'Pause',
  [SAVE_TYPE.PLAN_CHANGE]: 'Switch Plan',
  [SAVE_TYPE.REDIRECT]: 'Redirect URL',
  [SAVE_TYPE.TRIAL_EXTENSION]: 'Trial Extension',
  [SAVE_TYPE.ABANDON]: 'Abandon',
};

export const filterConfigMap: Record<string, IFilterConfig> = {
  [SESSION.TRIAL]: {
    label: 'Subscription Status',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'On Trial' : 'Active Subscription'),
  },
  [SESSION.SEGMENT_ID]: {
    label: 'Segment',
    type: 'string[]',
    optionToLabel: (d: string, { getters }: { getters: any }) => getters.segmentIdToString(d) as string,
  },
  [SESSION.RESPONSE]: {
    label: 'Response',
    type: 'string[]',
    optionToLabel: toString,
  },
  [SESSION.ABORTED]: {
    label: 'Abandoned',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'Trial' : 'Active'),
  },
  [SESSION.CANCELED]: {
    label: 'Cancelled',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'Trial' : 'Active'),
  },
  [SESSION.PAUSE_DURATION]: {
    label: 'Pause Duration',
    type: 'number[]',
  },
  [SESSION.COUPON_ID]: {
    label: 'Coupon ID',
    type: 'string[]',
  },
  [SESSION.PLAN_ID]: {
    label: 'Plan ID',
    type: 'string[]',
    optionToLabel: (d: string, { getters }: { getters: any }) => getters.stripePriceDescription(d, false, true) as string,
  },
  [SESSION.BILLING_INTERVAL]: {
    label: 'Billing Interval',
    type: 'string[]',
    optionToLabel: (d: string) => billingIntervalToLabel[d] || d,
  },
  [SESSION.OFFER_TYPE]: {
    label: 'Offer Accepted',
    type: 'string[]',
    optionToLabel: (d: string) => offerTypeToLabel[d] || d,
  },
  [SESSION.SAVE_TYPE]: {
    label: 'Save Type',
    type: 'string[]',
    optionToLabel: (d: string) => saveTypeToLabel[d] || d,
  },
  [SESSION.DAY]: {
    label: 'Day',
  },
  [SESSION.MONTH]: {
    label: 'Month',
  },
  [SESSION.INVOICE_MONTH]: {
    label: 'Invoice Month',
  },
  [SESSION.BOUNCED]: {
    label: 'Bounced',
  },
  [SESSION.AGE_MONTHS]: {
    label: 'Subscription Age - Months',
    type: 'number[]',
    optionToLabel: (d: number) => `${d} - ${d + 1} ${d === 0 ? 'month' : 'months'}`,
  },
  [SESSION.AGE_QUARTERS]: {
    label: 'Subscription Age - Quarters',
    type: 'number[]',
    optionToLabel: (d: number) => `${d} - ${d + 1} ${d === 0 ? 'quarter' : 'quarters'}`,
  },
  [SESSION.AGE_YEARS]: {
    label: 'Subscription Age - Years',
    type: 'number[]',
    optionToLabel: (d: number) => `${d} - ${d + 1} ${d === 0 ? 'year' : 'years'}`,
  },
};

export const REACTIVATION_CAMPAIGN = {
  BLUEPRINT: 'campaignBlueprintId',
  ORG_ID: 'orgId',
  SEGMENT_ID: 'segmentId',
  ACTIVE: 'active',
  OUTCOME: 'outcome',
  INVOICE_MONTH: 'invoiceMonth',
};

export const DUNNING_CAMPAIGN = {
  BLUEPRINT: 'campaignBlueprintId',
  ORG_ID: 'orgId',
  SEGMENT_ID: 'segmentId',
  TRIAL: 'trial',
  FAIL_REASON: 'failReason',
  RECOVERED: 'recovered',
  ACTIVE: 'active',
  INVOICE_MONTH: 'invoiceMonth',
  INVOICE_DAY: 'invoiceDay',
  INVOICE_WEEK: 'invoiceWeek',
  OUTCOME: 'outcome',
};

export const dunningCampaignFilterConfigMap: Record<string, IFilterConfig> = {
  [DUNNING_CAMPAIGN.BLUEPRINT]: {
    label: 'Campaign',
    type: 'string[]',
    optionToLabel: (d: string, { getters }: { getters: any }) => getters.campaignBlueprintIdToString(d) as string,
  },
  [DUNNING_CAMPAIGN.TRIAL]: {
    label: 'Subscription Status',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'On Trial' : 'Active Subscription'),
  },
  [DUNNING_CAMPAIGN.FAIL_REASON]: {
    label: 'Failure Reason',
    type: 'string[]',
    // capitalize first letter of every word and remove underscores
    optionToLabel: (d: string) => d.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()),
  },
  [DUNNING_CAMPAIGN.RECOVERED]: {
    label: 'Recovered',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'Recovered' : 'Not Recovered'),
  },
  [DUNNING_CAMPAIGN.ACTIVE]: {
    label: 'Active',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'Active Campaign' : 'Inactive Campaign'),
  },
};

export const reactivationCampaignFilterConfigMap: Record<string, IFilterConfig> = {
  [REACTIVATION_CAMPAIGN.BLUEPRINT]: {
    label: 'Campaign',
    type: 'string[]',
    optionToLabel: (d: string, { getters }: { getters: any }) => getters.campaignBlueprintIdToString(d) as string,
  },
  [REACTIVATION_CAMPAIGN.ACTIVE]: {
    label: 'Active',
    type: 'boolean[]',
    optionToLabel: (d: boolean) => (d ? 'Active Campaign' : 'Inactive Campaign'),
  },
};

export const EMAIL = {
  STATUS: 'status',
  BLUEPRINT: 'emailBlueprintId',
  SEND_DATE_MONTH: 'sendDateMonth',
  SEND_DATE: 'sendDate',
  SENT: 'isSent',
  DELIVERED: 'isDelivered',
  CLICKED: 'isClicked',
  OPENED: 'isOpened',
  BOUNCED: 'isBounced',
};

const oneDayAgo = timeDay.offset(new Date(), -1);
const twoHoursAgo = timeHour.offset(new Date(), -2);
const twelveHoursAgo = timeHour.offset(new Date(), -12);

export const getEmailStatus = (e: IEmail, campaign?: ICampaign): EmailStatus => {
  if (e.isRecovered) {
    return EmailStatus.RECOVERED;
  }
  if (e.isClicked) {
    return EmailStatus.CLICKED;
  }
  if (e.isOpened) {
    return EmailStatus.OPENED;
  }
  if (e.isDelivered) {
    return EmailStatus.DELIVERED;
  }
  if (e.isBounced) {
    return EmailStatus.BOUNCED;
  }
  if (e.isSent) {
    return EmailStatus.SENT;
  }
  if (e.isSkipped) {
    return EmailStatus.SKIPPED;
  }
  if (campaign?.isActive === false) {
    return EmailStatus.INACTIVE;
  }
  // if more than two hours ago
  if (e.sendDate < twelveHoursAgo) {
    return EmailStatus.NOT_SENT;
  }
  return EmailStatus.SCHEDULED;
};

export interface IEmailStatusConfig {
  color: string;
  label: string;
  name: EmailStatus;
  icon: string;
}

export const emailStatusConfigs: IEmailStatusConfig[] = [
  {
    // color: '#BB6BD9',
    color: '#d4d4d8',
    label: 'Not Sent',
    name: EmailStatus.NOT_SENT,
    icon: 'minus',
  },
  {
    // color: '#BB6BD9',
    color: '#d4d4d8',
    label: 'Sent',
    name: EmailStatus.SENT,
    icon: 'check',
  },
  {
    // color: '#BB6BD9',
    color: '#9CA3AF',
    label: 'Delivered',
    name: EmailStatus.DELIVERED,
    icon: 'check-double',
  },
  {
    color: '#60A5FA',
    // color: '#491249',
    label: 'Opened',
    name: EmailStatus.OPENED,
    icon: 'check-double',
  },
  {
    color: '#4338CA',
    // color: '#3C5CCF',
    label: 'Clicked',
    name: EmailStatus.CLICKED,
    icon: 'angle-up',
  },
  {
    color: '#059669',
    label: 'Recovered',
    name: EmailStatus.RECOVERED,
    icon: 'dollar-sign',
  },
  {
    color: '#FBBF24',
    // color: '#D8DFF6',
    label: 'Bounced',
    name: EmailStatus.BOUNCED,
    icon: 'exclamation',
  },
  {
    color: '#D4D4D8',
    label: 'Scheduled',
    name: EmailStatus.SCHEDULED,
    icon: 'clock',
  },
  {
    color: '#D4D4D899',
    label: 'Skipped',
    name: EmailStatus.SKIPPED,
    icon: 'minus',
  },
  {
    color: '#D4D4D899',
    label: 'Inactive',
    name: EmailStatus.INACTIVE,
    icon: 'minus',
  },
];

export const emailStatusMap = emailStatusConfigs.reduce(
  (acc, cur) => {
    acc[cur.name] = cur;
    return acc;
  },
  {} as Record<EmailStatus, IEmailStatusConfig>
);

export const getEmailStatusConfig = (e: IEmail, c?: ICampaign): IEmailStatusConfig => emailStatusMap[getEmailStatus(e, c)];

const emailDateFields = ['sendDate', 'createdAt', 'updatedAt', 'recoverDate', 'openDate', 'bounceDate', 'clickDate', 'deliverDate'];
export const processEmail = (raw: any) => {
  const d = { ...raw };
  emailDateFields.forEach((f) => {
    if (d[f]) {
      d[f] = new Date(d[f]);
    }
  });
  d.status = getEmailStatus(d);
  d.statusConfig = getEmailStatusConfig(d);
  return d;
};

const campaignDateFields = [
  'createdAt',
  'updatedAt',
  'recoverDate',
  'invoiceVoidDate',
  'invoiceUncollectibleDate',
  'invoiceDeleteDate',
  'subscriptionCancelDate',
  'subscriptionDeleteDate',
  'subscriptionActiveDate',
];
const failedPaymentDateFields = ['createdAt', 'updatedAt'];
const failedPaymentAttemptDateFields = ['declineDate'];
const invoiceDateFields = ['created', 'dbCreatedAt', 'dbUpdatedAt', 'periodStart', 'periodEnd'];
const subscriptionDateFields = ['created', 'canceled_at', 'ended_at', 'periodStart', 'periodEnd'];

export const processCampaign = (raw: any) => {
  const d = { ...raw };
  campaignDateFields.forEach((f) => {
    if (d[f]) {
      d[f] = new Date(d[f]);
    }
  });
  if (d.failedPayment) {
    failedPaymentDateFields.forEach((cf) => {
      if (d.failedPayment[cf]) {
        d.failedPayment[cf] = new Date(d.failedPayment[cf]);
      }
    });
    if (d.failedPayment.allAttempts && d.failedPayment.allAttempts.length) {
      const firstAttempt = d.failedPayment.allAttempts[d.failedPayment.allAttempts.length - 1];
      d.initialDeclineReason = firstAttempt.declineReason?.replace('_', ' ').replace('_', ' ');
      d.failedPayment.allAttempts.forEach((a: any) => {
        failedPaymentAttemptDateFields.forEach((af) => {
          if (a[af]) {
            a[af] = new Date(a[af]);
          }
        });
      });
    }
  }
  if (d.invoice) {
    invoiceDateFields.forEach((f) => {
      if (d.invoice[f]) {
        d.invoice[f] = new Date(d.invoice[f]);
      }
    });
  }
  if (d.emails) {
    d.emails.forEach((e: any) => {
      emailDateFields.forEach((ef) => {
        if (e[ef]) {
          e[ef] = new Date(e[ef]);
        }
      });
      e.status = getEmailStatus(e, d);
      e.statusConfig = getEmailStatusConfig(e, d);
    });
    d.emails.sort((a: IEmail, b: IEmail) => ascending(a.sendDate, b.sendDate));
  }
  // mark if it was recovered by auto-retry (aka not from email)
  if (d.recovered) {
    const recoveredFromEmail = (d.emails || []).reduce((acc: boolean, cur: IEmail) => acc || cur.isRecovered, false);
    if (!recoveredFromEmail && d.failedPayment?.allAttempts?.length) {
      d.recoveredFromRetry = true;
    }
  }
  // figure out if the dunning campaign was created after the initial invoice failure
  // check if invoice created date is at least 12 hours before failed payment createdAt date
  if (d.invoice?.created && d.failedPayment?.createdAt) {
    if (d.invoice.created < timeHour.offset(d.failedPayment.createdAt, -12)) {
      d.inherited = true;
    }
  }
  // figure out if the dunning campaign was created after the initial invoice failure
  // check if invoice created date is at least 12 hours before failed payment createdAt date
  if (d.invoice?.created && d.createdAt) {
    if (d.invoice.created < timeHour.offset(d.createdAt, -12)) {
      d.inherited = true;
    }
  }
  // figure out why the campaign is inactive
  if (d.isActive === false) {
    if (d.invoiceVoided && d.invoiceVoidDate) {
      d.reasonInactive = `Invoice voided on ${dateTimeLabelFormat(d.invoiceVoidDate)} `;
    }
    if (d.invoiceUncollectible && d.invoiceUncollectibleDate) {
      d.reasonInactive = `Invoice uncollectible on ${dateTimeLabelFormat(d.invoiceUncollectibleDate)} `;
    }
    if (d.invoiceDeleted && d.invoiceDeleteDate) {
      d.reasonInactive = `Invoice deleted on ${dateTimeLabelFormat(d.invoiceDeleteDate)} `;
    }
    if (d.subscriptionCanceled && d.subscriptionCancelDate) {
      d.reasonInactive = `Subscription cancelled on ${dateTimeLabelFormat(d.subscriptionCancelDate)} `;
    }
    if (d.subscriptionDeleted && d.subscriptionDeleteDate) {
      d.reasonInactive = `Subscription deleted on ${dateTimeLabelFormat(d.subscriptionDeleteDate)} `;
    }
    if (d.subscriptionActive && d.subscriptionActiveDate) {
      d.reasonInactive = `Subscription active on ${dateTimeLabelFormat(d.subscriptionActiveDate)} `;
    }
    if (d.recovered && d.recoverDate) {
      d.reasonInactive = `Recovered on ${dateTimeLabelFormat(d.recoverDate)} `;
    }
  }

  let outcome = 'Unrecovered'; // Default outcome
  if (d.recoveredViaEmail) {
    outcome = 'Recovered: Email Campaign';
  } else if (d.recoveredViaSms) {
    outcome = 'Recovered: SMS Campaign';
  } else if (d.recoveredViaFailedPaymentWall) {
    outcome = 'Recovered: Failed Payment Wall';
  } else if (d.recoveredViaRetryStrategy !== null) {
    outcome = 'Recovered: Precision Retry';
  } else if (d.recoveredViaAutoRetry) {
    outcome = 'Recovered: Pre-Email Auto Retry';
  } else if (d.recovered) {
    outcome = 'Recovered: Other';
  } else if (d.subscriptionDeleted) {
    outcome = 'Inactive: Subscription Deleted';
  } else if (d.subscriptionCanceled) {
    outcome = 'Inactive: Subscription Cancelled';
  } else if (d.invoiceVoided) {
    outcome = 'Inactive: Invoice Voided';
  } else if (d.invoiceDeleted) {
    outcome = 'Inactive: Invoice Deleted';
  } else if (d.invoiceUncollectible) {
    outcome = 'Inactive: Invoice Uncollectible';
  } else if (d.automaticDeactivationDate) {
    outcome = 'Inactive: Deactivated after 60 days';
  } else if (d.isActive) {
    outcome = 'Actively Recovering';
  }
  d.outcome = outcome;

  return d;
};

export const EVENT_PROP = {
  ORG_ID: 'orgId',
  EVENT_NAME: 'event',
  EVENT_TYPE: 'eventType',
  VERIFIED: 'verified',
  IP: 'ip',
  UID: 'uid',
  CUSTOMER_ID: 'customerId',
  USER_UID: 'userUid',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const eventFilterConfigMap: Record<string, IFilterConfig> = {
  [EVENT_PROP.EVENT_NAME]: {
    label: 'Event',
    type: 'string[]',
    optionToLabel: (d: string) => {
      // snake_case to Title Case
      const words = d.split('_');
      return words.map((w) => w[0].toUpperCase() + w.slice(1)).join(' ');
    },
  },
  [EVENT_PROP.USER_UID]: {
    label: 'User ID',
    type: 'string[]',
  },
  [EVENT_PROP.CUSTOMER_ID]: {
    label: 'Customer Billing ID',
    type: 'string[]',
  },
  [EVENT_PROP.UID]: {
    label: 'Customer ID',
    type: 'string[]',
  },
};

export const COMPANY_HEALTH = {
  BILLING_INTERVAL: 'billingInterval',
  PLANS: 'plans',
  SUBSCRIPTION_AGE: 'subscriptionAge',
};

export const companyHealthFilterConfigMap: Record<string, IFilterConfig> = {
  [COMPANY_HEALTH.BILLING_INTERVAL]: {
    label: 'Billing Interval',
    type: 'string[]',
    optionToLabel: (d: string) => billingIntervalToLabel[d.toUpperCase()] || d,
  },
  [COMPANY_HEALTH.PLANS]: {
    label: 'Plan',
    type: 'string[]',
    optionToLabel: (d: string, { getters }: { getters: any }) => getters.stripePriceDescription(d, false, true) as string,
  },
  [COMPANY_HEALTH.SUBSCRIPTION_AGE]: {
    label: 'Subscription Age',
    type: 'number[]',
    optionToLabel: (d: number) => `${d - 1} - ${d} ${d === 1 ? 'month' : 'months'}`,
  },
};
