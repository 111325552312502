import { IStripeCoupon, IStripePrice, IStripeProduct } from '@/store/stripe-types';
import {
  IOrg,
  IDunning,
  IReactivation,
  IUser,
  IEmailFlow,
  INotifications,
  ICustomAttribute,
  IssueUpdate,
  IPrecisionRetryConfig,
} from '@/store/types';
import Axios from '@/helpers/axios-wrapper';

const RESOURCE = '/api/orgs';

export default {
  /**
   * @param {String} orgId is the _id of the organization
   */
  get(orgId: string): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getPopulated(orgId: string): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/populated`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getMembers(orgId: string): Promise<IUser[]> {
    return Axios.get<IUser[]>(`${RESOURCE}/${orgId}/team`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getIssues(orgId: string): Promise<any[]> {
    return Axios.get<any[]>(`${RESOURCE}/${orgId}/get-issues`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  updateIssue(orgId: string, body: IssueUpdate): Promise<any[]> {
    return Axios.put<any[]>(`${RESOURCE}/${orgId}/update-issue`, body);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getToken(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/token`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getTestToken(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/token?mode=test`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getDataApiKey(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/data-api-key`);
  },
  /**
  /**
   * @param {String} accountId is the _id of the organization's churnkey account
   * @param {Object} couponData is the coupon data to create a stripe coupon
   */
  createCoupon(orgId: string, couponData: any): Promise<string> {
    return Axios.post<string>(`${RESOURCE}/${orgId}/coupon`, couponData);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getDataApiTestKey(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/data-api-key?mode=test`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getWebhookToken(orgId: string): Promise<{ webhookSecret: string | undefined }> {
    return Axios.get<{ webhookSecret: string | undefined }>(`${RESOURCE}/${orgId}/webhook-token`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  regenerateToken(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/regenerate-token?key=api`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  regenerateTestToken(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/regenerate-token?key=api&mode=test`);
  },
  regenerateDataApiKey(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/regenerate-token?key=data`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  regenerateDataApiKeyTest(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/regenerate-token?key=data&mode=test`);
  },
  /**
   * @param {Object} segments to reorder
   */
  reorder(orgId: string, segments: any): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/reorder-segments`, segments);
  },
  /**
   * @param {Object} campaign blueprints to reorder
   */
  reorderCampaignBlueprints(orgId: string, data: { campaignBlueprints: string[] }): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/reorder-campaign-blueprints`, data);
  },
  /**
   * @param {Object} dunning exclusions to reorder
   */
  reorderDunningExclusions(orgId: string, data: { exclusions: string[] }): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/reorder-dunning-exclusions`, data);
  },
  /**
   * @param {Object} campaign blueprints to reorder
   */
  reorderReactivationCampaignBlueprints(orgId: string, data: { campaignBlueprints: string[] }): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/reorder-reactivation-campaign-blueprints`, data);
  },
  /**
   * @param {Object} dunning exclusions to reorder
   */
  reorderReactivationExclusions(orgId: string, data: { exclusions: string[] }): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/reorder-reactivation-exclusions`, data);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  regenerateWebhookToken(orgId: string): Promise<string> {
    return Axios.get<string>(`${RESOURCE}/${orgId}/regenerate-token?key=webhook`);
  },

  /**
   * Get's org account information from Stripe
   * @param {String} orgId is the _id of the organization
   */
  getCoupons(orgId: string): Promise<IStripeCoupon[]> {
    return Axios.get<IStripeCoupon[]>(`${RESOURCE}/${orgId}/stripe/coupons?limited=true`);
  },

  /**
   * Get's org account information from Stripe
   * @param {String} orgId is the _id of the organization
   * @param {String} couponId is the _id of the coupon to import
   */
  importCoupon(orgId: string, couponId: string): Promise<IStripeCoupon> {
    return Axios.post<IStripeCoupon>(`${RESOURCE}/${orgId}/coupons/import`, { couponId });
  },

  /**
   * Get's org account information from Stripe
   * @param {String} orgId is the _id of the organization
   */
  getPrices(orgId: string): Promise<IStripePrice[]> {
    return Axios.get<IStripePrice[]>(`${RESOURCE}/${orgId}/stripe/prices`);
  },

  /**
   * Get's org account information from Stripe
   * @param {String} orgId is the _id of the organization
   */
  getProducts(orgId: string): Promise<IStripeProduct[]> {
    return Axios.get<IStripeProduct[]>(`${RESOURCE}/${orgId}/stripe/products`);
  },

  /**
   * Test org account's Stripe Connect status
   * @param {String} orgId is the _id of the organization
   */
  testStripeConnect(orgId: string, stripeMode = 'live'): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/stripe/status?mode=${stripeMode}`);
  },
  /**
   * Test org account's Stripe Connect status
   * @param {String} orgId is the _id of the organization
   */
  testStripeApiKey(orgId: string, stripeMode = 'live'): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/stripe/status?mode=${stripeMode}&useApiKey=true`);
  },
  /**
   * Test org account's Braintree Connect status
   * @param {String} orgId is the _id of the organization
   */
  testBraintreeConnect(orgId: string, mode = 'live'): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/braintree/status?mode=${mode}`);
  },
  /**
   * Test org account's Chargebee Connect status
   * @param {String} orgId is the _id of the organization
   */
  testChargebeeConnect(orgId: string, mode = 'live'): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/chargebee/status?mode=${mode}`);
  },
  /**
   * Test org account's Paddle Connect status
   * @param {String} orgId is the _id of the organization
   */
  testPaddleConnect(orgId: string, mode = 'live'): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/paddle/status?mode=${mode}`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} org company attributes
   * @param {String} org.name company name field
   * @param {String} org.industry company's industry
   */
  update(
    orgId: string,
    org: {
      name?: string;
      industry?: string;
      paymentProvider?: string;
      customAttributes?: ICustomAttribute[];
      emailFlow?: IEmailFlow;
      dunningWaitlist?: boolean;
      reactivationWaitlist?: boolean;
      discountCooldown?: number | null;
      pauseCooldown?: number | null;
      plans?: Array<any>;
      providerSettings?: any;
      smartRetries?: IPrecisionRetryConfig;
      translations?: Record<string, Record<string, string>>;
    }
  ): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/${orgId}`, org);
  },
  // eslint-disable-next-line
  enable2FA(orgId: string): Promise<any> {
    return Axios.post<any>(`${RESOURCE}/${orgId}/enable-2fa`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} member to add
   * @param {String} member.name Last name of new team member
   * @param {String} member.email email of new team member
   * @param {Boolean} member.role show whether new team member has admin privileges
   */
  inviteUser(orgId: string, member: { name: string; email: string; role: boolean }): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/invite`, member);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  updateNotifications(orgId: string, notifications: INotifications): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/${orgId}/notifications`, notifications);
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  updateBranding(
    orgId: string,
    { primaryColor, brandImage, brandTheme, confetti }: { primaryColor?: string; brandImage?: string; brandTheme?: string; confetti?: boolean }
  ): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/${orgId}/branding`, {
      primaryColor,
      brandTheme: brandTheme || 'default',
      brandImage: brandImage || '',
      confetti: confetti || false,
    });
  },
  uploadImage(orgId: string, image: File, onUploadProgress: (progressEvent: any) => void): Promise<any> {
    const formData = new FormData();
    formData.append('file', image);
    return Axios.post<any>(`${RESOURCE}/${orgId}/upload-image`, formData, {
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // eslint-disable-next-line
  testSlackConnection(orgId: string): Promise<any> {
    return Axios.get<any>(`${RESOURCE}/${orgId}/slack/test`);
  },
  // eslint-disable-next-line
  removeSlackConnection(orgId: string): Promise<any> {
    return Axios.get<any>(`${RESOURCE}/${orgId}/slack/remove`);
  },
  // eslint-disable-next-line
  removeStripeConnection(orgId: string): Promise<any> {
    return Axios.get<any>(`${RESOURCE}/${orgId}/remove-stripe-credentials`);
  },
  // eslint-disable-next-line
  removeStripeApiKey(orgId: string, mode: string): Promise<any> {
    return Axios.get<any>(`${RESOURCE}/${orgId}/remove-stripe-api-key?mode=${mode}`);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} secrets to save, expecting prod and test
   */
  saveStripeApiKeys(
    orgId: string,
    secrets: {
      prod: { stripeApiKey: string };
      test: { stripeApiKey: string };
    }
  ): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/stripe/save-api-keys`, secrets);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} secrets to save, expecting prod and test
   */
  saveStripeAccountId(orgId: string, stripeAccountId: string): IOrg {
    return Axios.post(`${RESOURCE}/${orgId}/stripe/save-account-id`, { stripeAccountId });
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} secrets to save, expecting prod and test
   */
  saveBraintreeSecrets(
    orgId: string,
    secrets: {
      prod: { merchantId: string; publicKey: string; privateKey: string };
      test: { merchantId: string; publicKey: string; privateKey: string };
    }
  ): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/braintree/save-secrets`, secrets);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} secrets to save, expecting prod and test
   */
  saveChargebeeSecrets(
    orgId: string,
    secrets: {
      prod: { site: string; apiKey: string };
      test: { site: string; apiKey: string };
    }
  ): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/chargebee/save-secrets`, secrets);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} secrets to save, expecting prod and test
   */
  savePaddleSecrets(
    orgId: string,
    secrets: {
      prod: { vendorId: string; apiKey: string; publicKey: string };
      test: { vendorId: string; apiKey: string; publicKey: string };
    }
  ): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/paddle/save-secrets`, secrets);
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} version paddle version ("CLASSIC" or "BILLING")
   */
  savePaddleVersion(orgId: string, version: string): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/paddle/save-version`, { version });
  },
  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} version product catalog version ("1" or "2")
   */
  saveChargebeeVersion(orgId: string, version: string): Promise<IOrg> {
    return Axios.post<IOrg>(`${RESOURCE}/${orgId}/chargebee/save-version`, { version });
  },
  /**
   * @param {String} orgId is the _id of the organization
   */
  getDunningSettings(orgId: string): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/dunning`);
  },

  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} updates dunning settings to update
   * @param {String} updates.enabled activate or deactivate dunning emails
   */
  updateDunningSettings(orgId: string, updates: Partial<IDunning>): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/${orgId}/dunning`, updates);
  },

  /**
   * @param {String} orgId is the _id of the organization
   */
  getReactivationSettings(orgId: string): Promise<IOrg> {
    return Axios.get<IOrg>(`${RESOURCE}/${orgId}/reactivation`);
  },

  /**
   * @param {String} orgId is the _id of the organization
   * @param {Object} updates reactivation settings to update
   * @param {Boolean} updates.enabled true to enable reactivation emails (false to disable)
   */
  updateReactivationSettings(orgId: string, updates: Partial<IReactivation>): Promise<IOrg> {
    return Axios.put<IOrg>(`${RESOURCE}/${orgId}/reactivation`, updates);
  },
};
