<template>
  <div class="">
    <div>
      <p class="text-xl text-brand-black font-medium leading-8">
        <slot></slot>
      </p>
    </div>
    <div class="flex items-center justify-between mt-6">
      <div class="flex items-center space-x-4">
        <img class="rounded-full h-full max-h-12" :src="imageAsset" />
        <div class="text-brand-brown font-semibold">
          <slot name="author"></slot>
        </div>
      </div>
      <div>
        <img src="@/assets/img/quotation-mark.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import OneUp from '@/assets/img/people/oneup-headshot.png';
import Jasper from '@/assets/img/people/jarvis-headshot.jpg'
import ImpeccableInvestor from '@/assets/img/people/impeccable-investor-headshot.jpg'
import Paperbell from '@/assets/img/people/paperbell-headshot.jpg'

export default {
  name: 'Testimonial',
  props: ['image'],
  data() {
    return {
      imageMap: {
        OneUp,
        Jasper,
        ImpeccableInvestor,
        Paperbell
      }
    }
  },
  computed: {
    imageAsset() {
      return this.imageMap[this.image]
    }
  }
};
</script>