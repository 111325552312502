import { IUser } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/users';

export default {
  /**
   * Register new user
   * @param {object} data - user properties
   */
  create(data: any): IUser {
    return Axios.post(`${RESOURCE}`, data);
  },
  /**
   * Resend email confirmation
   * @param {string} user.id - user Identifier
   */
  sendConfirmationEmail(id: string) {
    return Axios.get(`${RESOURCE}/{$id}/confirm`);
  },
  /**
   * Trigger password reset
   * @param {object} data - user properties
   */
  resetPassword(data: any): IUser {
    return Axios.post(`${RESOURCE}/reset`, data);
  },
  /**
   * Update user by id
   * @param {string} user.id - user Identifier
   * @param {object} data - user Info Properties
   */
  updateSelf(data: any): IUser {
    return Axios.put(`${RESOURCE}/self`, data);
  },

  /**
   * Update team member role
   * @param {string} user.id - user Identifier
   * @param {object} data - user role property
   */
  updateUserRole(id: string, data: any): IUser {
    return Axios.put(`${RESOURCE}/${id}/role`, data);
  },
  /**
   * Update team member issue notifications
   * @param {string} user.id - user Identifier
   * @param {object} data - user properties
   */
  updateUserProperties(id: string, update: { name?: string; developerNotifications?: boolean; email?: string }): IUser {
    return Axios.put(`${RESOURCE}/${id}/properties`, update);
  },
  /**
   * Delete team member
   * @param {string} user.id - user Identifier
   */
  deleteUser(id: string): IUser {
    return Axios.delete(`${RESOURCE}/${id}`);
  },
  /**
   * Get Stripe portal session
   */
  getIntercomSignature(id: string) {
    return Axios.get(`${RESOURCE}/${id}/intercom`);
  },
  /**
   * Get ck signature for events
   */
  getChurnkeySignature(id: string) {
    return Axios.get(`${RESOURCE}/${id}/churnkey-signature`);
  },
  /**
   * Get Stripe portal session
   */
  getPortalSession(id: string, returnURL: string) {
    const params = returnURL ? `?return_url=${returnURL}` : '';
    return Axios.get(`${RESOURCE}/${id}/billing${params}`);
  },
  /**
   * Get Billflow HMAC signature
   */
  getBillflowSignature(id: string) {
    return Axios.get(`${RESOURCE}/${id}/billflowHMAC`);
  },
  /**
   * Get Stripe Checkout session
   */
  createCheckoutSession(id: string, body: { returnUrl?: string; discounts?: any[]; allow_promotion_codes?: boolean; line_items?: any[] }) {
    return Axios.post(`${RESOURCE}/${id}/create-checkout-session`, body);
  },
  /**
   * Get Hubspot signature
   */
  getHubspotToken(id: string) {
    return Axios.get(`${RESOURCE}/${id}/hubspot-token`);
  },
  deleteEndUser(userId: string, body: { email: string }): any {
    return Axios.post(`${RESOURCE}/${userId}/dsr/delete`, body);
  },
  getEndUserData(userId: string, body: { email: string }): any {
    return Axios.post(`${RESOURCE}/${userId}/dsr/access`, body);
  },
};
