import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { getCurrencyDivisor, currencyFormatWithDivisor } from '@/helpers/currency-utils';
import {
  BlueprintStep,
  CancelSchedule,
  CustomAttributeType,
  DiscountType,
  InvoiceStatusType,
  CampaignType,
  CampaignSchedule,
  CampaignInvoiceDiscountType,
  CampaignOffer,
  FreeformSentimentCategory,
  FeedbackEmotion,
  IBlueprint,
  IConfirmStep,
  IDiscountCoupon,
  IFreeformStep,
  IOfferStep,
  IPlanSummary,
  ISegment,
  ISession,
  isISessionContactOffer,
  isISessionDiscountOffer,
  isISessionPauseOffer,
  isISessionRedirectOffer,
  ISurveyStep,
  LoadState,
  CampaignOfferType,
  PauseInterval,
  SaveState,
  SaveType,
  SegmentAttribute,
  SegmentFilterValue,
  SegmentOperand,
  SegmentedFeature,
  SessionOffer,
  StepType,
  StepPlace,
  SubscriptionInterval,
  SubscriptionStatus,
  SubscriptionDiscount,
  isISessionTrialExtensionOffer,
  isISessionNewPlanOffer,
  ICampaignDiscountOffer,
  ICampaignInvoiceDiscountOffer,
  AIJobStatus,
  AIJobType,
  ICoupon,
  ICampaign,
  CustomDiscountDuration,
  PaddleVersion,
  IEmail,
} from './types';
import { IOfferContact, IOfferDiscount, IOfferPause, IOfferPlanChange, IOfferRedirect, IOfferTrialExtension, Offer, OfferType } from '@/models/Offer';

export const SESSION_FETCH_LIMIT = 4000;

export const WIDGET_THEME = {
  DEFAULT: 'default',
  DYNAMIC_THEME: 'theme-v2',
};

// stripe product plans
export const PRICE_PLAN_ID = {
  FREE: 'price_1NLqnHKBb7Vk4xHZGafQSOlT',
  LAUNCH: 'price_1NLqnGKBb7Vk4xHZFBGxayZw',
  STANDARD: 'price_1NLqnGKBb7Vk4xHZnhPi9bYR',
  PREMIUM: 'price_1Nbn1kKBb7Vk4xHZsMeQo1ZT',
  LITE: 'price_1NLqnCKBb7Vk4xHZCr7TSkma',
  LITE_STRIPE: 'price_1NLqnBKBb7Vk4xHZQZeGGnk8',
};

export const PRICE_PLAN: { [key: string]: IPlanSummary } = {
  [PRICE_PLAN_ID.FREE]: {
    name: 'Free',
    amount: 0,
    description: 'Explore Churnkey with up to 10 cancellation sessions per month',
    interval: 'month',
  },
  [PRICE_PLAN_ID.LAUNCH]: {
    name: 'Launch Special',
    amount: 5000,
    description: 'Lock our launch special price in for life',
    interval: 'month',
  },
  [PRICE_PLAN_ID.STANDARD]: {
    name: 'Standard',
    amount: 10000,
    description: 'Unlimited cancellation sessions for businesses doing $10k+ MRR',
    interval: 'month',
  },
  [PRICE_PLAN_ID.PREMIUM]: {
    name: 'Premium',
    amount: 9500,
    description: 'MRR based pricing ($95+)',
    interval: 'month',
    metered: true,
  },
  [PRICE_PLAN_ID.LITE]: {
    name: 'Churnkey Lite',
    amount: 2999,
    description: 'Know your customers better with cancellation insights',
    interval: 'month',
    lite: true,
  },
  [PRICE_PLAN_ID.LITE_STRIPE]: {
    name: 'Churnkey Lite for Stripe Users',
    amount: 0,
    description: 'Know your customers better with cancellation insights',
    interval: 'month',
    lite: true,
  },
};

export const SUBSCRIPTION_INTERVAL: { [key in SubscriptionInterval]: SubscriptionInterval } = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const INVOICE_STATUS: { [key in InvoiceStatusType]: InvoiceStatusType } = {
  OPEN: 'OPEN',
  VOID: 'VOID',
  DRAFT: 'DRAFT',
  PAID: 'PAID',
};

export const AI_JOB_STATUS: { [key in AIJobStatus]: AIJobStatus } = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  READY: 'READY',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  ABORTED: 'ABORTED',
};

export const AI_JOB_TYPES: { [key in AIJobType]: AIJobType } = {
  GEN_BLUEPRINT: 'GEN_BLUEPRINT',
  REWORK_BLUEPRINT: 'REWORK_BLUEPRINT',
  REWORK_STEP: 'REWORK_STEP',
  REWORK_SURVEY_CHOICE: 'REWORK_SURVEY_CHOICE',
};

export const AI_JOB_ESTIMATED_DURATION = 90; // seconds

export const CAMPAIGN_TYPE: { [key in CampaignType]: CampaignType } = {
  DELINQUENCY: 'DELINQUENCY',
  RENEWAL: 'RENEWAL',
  EXPIRATION: 'EXPIRATION',
  REACTIVATION: 'REACTIVATION',
};

export const CAMPAIGN_SCHEDULE: { [key in CampaignSchedule]: CampaignSchedule } = {
  RECURRING: 'RECURRING' as CampaignSchedule,
  ONE_TIME: 'ONE_TIME' as CampaignSchedule,
};

// key is the campaign schedule, and value is an object with a few properties and texts
export const CAMPAIGN_LIST_UI = {
  [CAMPAIGN_SCHEDULE.RECURRING]: {
    link: '/reactivation/recurring-campaigns',
    title: 'Recurring Reactivation Campaigns',
    description: 'Not all customers who cancel are lost. Send targeted reactivation offers to customers most prone to return to your product.',
    addButtonText: 'New Recurring Campaign',
  },
  [CAMPAIGN_SCHEDULE.ONE_TIME]: {
    link: '/reactivation/one-time-campaigns',
    title: 'One-Time Reactivation Campaigns',
    description:
      'Send targeted reactivation campaigns that occur once and do not repeat. Perfect for seasonal or opportunistic situations. Can be sent as a series or as a single message.',
    addButtonText: 'New One-Time Campaign',
  },
};

export const CAMPAIGN_OFFER_TYPE: { [key in CampaignOfferType]: CampaignOfferType } = {
  DISCOUNT: 'DISCOUNT',
  INVOICE_DISCOUNT: 'INVOICE_DISCOUNT',
};

export const CAMPAIGN_OFFER_TYPE_LIST = [CAMPAIGN_OFFER_TYPE.DISCOUNT, CAMPAIGN_OFFER_TYPE.INVOICE_DISCOUNT];

export const CAMPAIGN_INVOICE_DISCOUNT_TYPE: { [key in CampaignInvoiceDiscountType]: CampaignInvoiceDiscountType } = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
};
export const CAMPAIGN_INVOICE_DISCOUNT_TYPE_LIST = [CAMPAIGN_INVOICE_DISCOUNT_TYPE.PERCENT, CAMPAIGN_INVOICE_DISCOUNT_TYPE.AMOUNT];

export const SAVE_TYPE: { [key in SaveType]: SaveType } = {
  PAUSE: 'PAUSE',
  DISCOUNT: 'DISCOUNT',
  CONTACT: 'CONTACT',
  PLAN_CHANGE: 'PLAN_CHANGE',
  REDIRECT: 'REDIRECT',
  TRIAL_EXTENSION: 'TRIAL_EXTENSION',
  ABANDON: 'ABANDON',
};

export const STEP_TYPE: { [key in StepType]: StepType } = {
  OFFER: 'OFFER',
  SURVEY: 'SURVEY',
  CONFIRM: 'CONFIRM',
  FREEFORM: 'FREEFORM',
};

export const STEP_PLACE: { [key in StepPlace]: StepPlace } = {
  INITIAL_OFFER: 'INITIAL_OFFER',
  SURVEY: 'SURVEY',
  CONFIRM: 'CONFIRM',
  FREEFORM: 'FREEFORM',
  FINAL_OFFER: 'FINAL_OFFER',
};

export const PLACE_TO_STEP_TYPE: { [key in StepPlace]: StepType } = {
  INITIAL_OFFER: STEP_TYPE.OFFER,
  SURVEY: STEP_TYPE.SURVEY,
  CONFIRM: STEP_TYPE.CONFIRM,
  FREEFORM: STEP_TYPE.FREEFORM,
  FINAL_OFFER: STEP_TYPE.OFFER,
};

export const SAVE_STATE: { [key in SaveState]: SaveState } = {
  SAVED: 'SAVED',
  UNSAVED: 'UNSAVED',
  SAVING: 'SAVING',
  ERROR_SAVING: 'ERROR_SAVING',
};

export const LOAD_STATE: { [key in LoadState]: LoadState } = {
  UNLOADED: 'UNLOADED',
  LOADED: 'LOADED',
  LOADING: 'LOADING',
  ERROR_LOADING: 'ERROR_LOADING',
};

export const SUBSCRIPTION_STATUS: { [key in SubscriptionStatus]: SubscriptionStatus } = {
  ACTIVE: 'ACTIVE',
  TRIALING: 'TRIALING',
  CANCELED: 'CANCELED',
  UNPAID: 'UNPAID',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  PAST_DUE: 'PAST_DUE',
};

export const SUBSCRIPTION_DISCOUNT: { [key in SubscriptionDiscount]: SubscriptionDiscount } = {
  ONCE: 'ONCE',
  REPEATING: 'REPEATING',
  FOREVER: 'FOREVER',
};

export const FEEDBACK_EMOTION: { [key in FeedbackEmotion]: FeedbackEmotion } = {
  GRATEFUL: 'GRATEFUL',
  PRACTICAL: 'PRACTICAL',
  ANGRY: 'ANGRY',
  CONFUSED: 'CONFUSED',
};

export const FREEFORM_SENTIMENT_CATEGORY: { [key in FreeformSentimentCategory]: FreeformSentimentCategory } = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
  NONE: 'NONE',
};

export const SEGMENT_ATTRIBUTE: { [key in SegmentAttribute]: SegmentAttribute } = {
  CUSTOMER_EMAIL: 'CUSTOMER_EMAIL',
  CUSTOMER_HAS_EMAIL: 'CUSTOMER_HAS_EMAIL',
  CUSTOMER_HAS_PHONE: 'CUSTOMER_HAS_PHONE',
  PLAN_ID: 'PLAN_ID',
  PRODUCT_ID: 'PRODUCT_ID',
  CURRENCY: 'CURRENCY',
  PRICE: 'PRICE',
  BILLING_INTERVAL: 'BILLING_INTERVAL',
  BILLING_INTERVAL_COUNT: 'BILLING_INTERVAL_COUNT',
  SUBSCRIPTION_AGE_MONTHS: 'SUBSCRIPTION_AGE_MONTHS',
  SUBSCRIPTION_START_DATE: 'SUBSCRIPTION_START_DATE',
  SUBSCRIPTION_STATUS: 'SUBSCRIPTION_STATUS',
  SUBSCRIPTION_STATUS_ON_CANCEL: 'SUBSCRIPTION_STATUS_ON_CANCEL',
  SUBSCRIPTION_DISCOUNT: 'SUBSCRIPTION_DISCOUNT',
  CUSTOMER_COUNTRY: 'CUSTOMER_COUNTRY',
  INVOICE_CURRENCY: 'INVOICE_CURRENCY',
  INVOICE_AMOUNT_DUE: 'INVOICE_AMOUNT_DUE',
  SURVEY_CHOICE: 'SURVEY_CHOICE',
  CANCEL_TYPE: 'CANCEL_TYPE',
  CANCEL_DATE: 'CANCEL_DATE',
  FREEFORM_SENTIMENT: 'FREEFORM_SENTIMENT',
};

const BASE_SEGMENT_ATTRIBUTES: SegmentAttribute[] = [
  SEGMENT_ATTRIBUTE.CUSTOMER_EMAIL,
  SEGMENT_ATTRIBUTE.PLAN_ID,
  SEGMENT_ATTRIBUTE.PRODUCT_ID,
  SEGMENT_ATTRIBUTE.PRICE,
  SEGMENT_ATTRIBUTE.BILLING_INTERVAL,
  SEGMENT_ATTRIBUTE.BILLING_INTERVAL_COUNT,
  SEGMENT_ATTRIBUTE.SUBSCRIPTION_AGE_MONTHS,
  SEGMENT_ATTRIBUTE.SUBSCRIPTION_START_DATE,
  SEGMENT_ATTRIBUTE.SUBSCRIPTION_DISCOUNT,
];
export const ENABLED_SEGMENT_ATTRIBUTES: { [key in SegmentedFeature]: SegmentAttribute[] } = {
  CANCEL_FLOW: [...BASE_SEGMENT_ATTRIBUTES, SEGMENT_ATTRIBUTE.CURRENCY, SEGMENT_ATTRIBUTE.SUBSCRIPTION_STATUS],
  DUNNING: [
    SEGMENT_ATTRIBUTE.CUSTOMER_HAS_EMAIL,
    SEGMENT_ATTRIBUTE.CUSTOMER_HAS_PHONE,
    ...BASE_SEGMENT_ATTRIBUTES,
    SEGMENT_ATTRIBUTE.INVOICE_CURRENCY,
    SEGMENT_ATTRIBUTE.INVOICE_AMOUNT_DUE,
    SEGMENT_ATTRIBUTE.CUSTOMER_COUNTRY,
  ],
  REACTIVATION: [
    SEGMENT_ATTRIBUTE.SURVEY_CHOICE,
    SEGMENT_ATTRIBUTE.CANCEL_TYPE,
    SEGMENT_ATTRIBUTE.CANCEL_DATE,
    SEGMENT_ATTRIBUTE.SUBSCRIPTION_STATUS_ON_CANCEL,
    SEGMENT_ATTRIBUTE.FREEFORM_SENTIMENT,
    ...BASE_SEGMENT_ATTRIBUTES,
    SEGMENT_ATTRIBUTE.CURRENCY,
    SEGMENT_ATTRIBUTE.CUSTOMER_COUNTRY,
  ],
};

export const CUSTOM_ATTRIBUTE_TYPE: { [key in CustomAttributeType]: CustomAttributeType } = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
};

export const SEGMENT_ATTRIBUTE_TYPE: { [key in SegmentAttribute]: CustomAttributeType } = {
  CUSTOMER_EMAIL: CUSTOM_ATTRIBUTE_TYPE.STRING,
  CUSTOMER_HAS_EMAIL: CUSTOM_ATTRIBUTE_TYPE.BOOLEAN,
  CUSTOMER_HAS_PHONE: CUSTOM_ATTRIBUTE_TYPE.BOOLEAN,
  PLAN_ID: CUSTOM_ATTRIBUTE_TYPE.STRING,
  PRODUCT_ID: CUSTOM_ATTRIBUTE_TYPE.STRING,
  CURRENCY: CUSTOM_ATTRIBUTE_TYPE.STRING,
  PRICE: CUSTOM_ATTRIBUTE_TYPE.NUMBER,
  BILLING_INTERVAL: CUSTOM_ATTRIBUTE_TYPE.STRING,
  BILLING_INTERVAL_COUNT: CUSTOM_ATTRIBUTE_TYPE.NUMBER,
  SUBSCRIPTION_AGE_MONTHS: CUSTOM_ATTRIBUTE_TYPE.NUMBER,
  SUBSCRIPTION_START_DATE: CUSTOM_ATTRIBUTE_TYPE.DATE,
  SUBSCRIPTION_STATUS: CUSTOM_ATTRIBUTE_TYPE.STRING,
  SUBSCRIPTION_STATUS_ON_CANCEL: CUSTOM_ATTRIBUTE_TYPE.STRING,
  SUBSCRIPTION_DISCOUNT: CUSTOM_ATTRIBUTE_TYPE.STRING,
  CUSTOMER_COUNTRY: CUSTOM_ATTRIBUTE_TYPE.STRING,
  INVOICE_CURRENCY: CUSTOM_ATTRIBUTE_TYPE.STRING,
  INVOICE_AMOUNT_DUE: CUSTOM_ATTRIBUTE_TYPE.NUMBER,
  SURVEY_CHOICE: CUSTOM_ATTRIBUTE_TYPE.STRING,
  CANCEL_TYPE: CUSTOM_ATTRIBUTE_TYPE.STRING,
  CANCEL_DATE: CUSTOM_ATTRIBUTE_TYPE.DATE,
  FREEFORM_SENTIMENT: CUSTOM_ATTRIBUTE_TYPE.STRING,
};

export const SEGMENT_OPERAND: { [key in SegmentOperand]: SegmentOperand } = {
  EQUAL: 'EQUAL',
  INCLUDES: 'INCLUDES',
  GT: 'GT',
  LT: 'LT',
  GTE: 'GTE',
  LTE: 'LTE',
  BETWEEN: 'BETWEEN',
  NOT_EQUAL: 'NOT_EQUAL',
  NOT_INCLUDES: 'NOT_INCLUDES',
  NOT_BETWEEN: 'NOT_BETWEEN',
};

// map from API to what state looks like locally
export const getBlueprintDataFromRaw = (blueprintResponse: IBlueprint) => blueprintResponse;

export const CANCEL_SCHEDULE: { [key in CancelSchedule]: CancelSchedule } = {
  PERIOD_END: 'PERIOD_END',
  IMMEDIATE: 'IMMEDIATE',
};

export const PAUSE_INTERVAL: { [key in PauseInterval]: PauseInterval } = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
};

export const CUSTOM_DISCOUNT_DURATION: { [key in CustomDiscountDuration]: CustomDiscountDuration } = {
  ONCE: 'ONCE',
  RECURRING: 'RECURRING',
};

const BASE_OFFERS: { [key in OfferType]: () => Offer } = {
  CONTACT: (): IOfferContact => ({
    guid: uuidv4(),
    offerType: OfferType.CONTACT,
    contactConfig: {},
  }),
  PAUSE: (): IOfferPause => ({
    guid: uuidv4(),
    offerType: OfferType.PAUSE,
    pauseConfig: {
      maxPauseLength: 2,
      pauseInterval: PAUSE_INTERVAL.MONTH,
    },
  }),
  PLAN_CHANGE: (): IOfferPlanChange => ({
    guid: uuidv4(),
    offerType: OfferType.PLAN_CHANGE,
    planChangeConfig: {
      options: [],
    },
  }),
  REDIRECT: (): IOfferRedirect => ({
    guid: uuidv4(),
    offerType: OfferType.REDIRECT,
    redirectConfig: {
      redirectUrl: '',
      redirectLabel: 'Go here',
    },
  }),
  DISCOUNT: (): IOfferDiscount => ({
    guid: uuidv4(),
    offerType: OfferType.DISCOUNT,
    discountConfig: {
      couponId: '',
      customAmount: 0,
      customDuration: 'ONCE',
    },
  }),
  TRIAL_EXTENSION: (): IOfferTrialExtension => ({
    guid: uuidv4(),
    offerType: OfferType.TRIAL_EXTENSION,
    trialExtensionConfig: {
      trialExtensionDays: 7,
    },
  }),
};

export const createBaseOffer = (type: OfferType): Offer => {
  const offer = BASE_OFFERS[type]();
  return offer;
};

const BASE_CAMPAIGN_OFFERS: { [key in CampaignOfferType]: () => CampaignOffer } = {
  DISCOUNT: (): ICampaignDiscountOffer => ({
    guid: uuidv4(),
    offerType: CAMPAIGN_OFFER_TYPE.DISCOUNT,
    discountConfig: {
      couponId: '',
    },
  }),
  INVOICE_DISCOUNT: (): ICampaignInvoiceDiscountOffer => ({
    guid: uuidv4(),
    offerType: CAMPAIGN_OFFER_TYPE.INVOICE_DISCOUNT,
    invoiceDiscountConfig: {
      type: 'AMOUNT' as DiscountType,
      amount: null,
    },
  }),
};

export const createBaseCampaignOffer = (type: CampaignOfferType): CampaignOffer => {
  const offer = BASE_CAMPAIGN_OFFERS[type]();
  return offer;
};

export const campaignInvoiceDiscountIsPercentage = (offer: ICampaignInvoiceDiscountOffer): boolean =>
  // eslint-disable-next-line implicit-arrow-linebreak
  offer.invoiceDiscountConfig.type === CAMPAIGN_INVOICE_DISCOUNT_TYPE.PERCENT;

const BASE_STEPS: { [key in StepType]: (options?: any) => BlueprintStep } = {
  OFFER: (options: { offerType: OfferType }): IOfferStep => ({
    guid: uuidv4(),
    stepType: STEP_TYPE.OFFER,
    enabled: true,
    header: 'How about a break?',
    description: 'We can put your account on hold for a bit.',
    offer: createBaseOffer(options.offerType),
  }),
  FREEFORM: (): IFreeformStep => ({
    guid: uuidv4(),
    stepType: STEP_TYPE.FREEFORM,
    enabled: true,
    header: 'Tell us what we could have done better.',
    description: 'We work hard to make the best product possible. Please let us know where we need to improve.',
    freeformConfig: {
      inputRequired: false,
      minLength: 40,
    },
  }),
  CONFIRM: (): IConfirmStep => ({
    guid: uuidv4(),
    stepType: STEP_TYPE.CONFIRM,
    enabled: true,
    header: 'Just making sure.',
    description: `You'll lose access to your account at the end of the month.`,
    confirmConfig: {
      requireAcknowledgement: true,
      discountNotice: false,
    },
  }),
  SURVEY: (): ISurveyStep => ({
    guid: uuidv4(),
    stepType: STEP_TYPE.SURVEY,
    enabled: true,
    header: `What's going wrong?`,
    description: `We'd love to hear why you are thinking about cancelling.`,
    survey: {
      guid: uuidv4(),
      randomize: false,
      choices: [
        { type: 'RADIO', value: 'Budget', guid: uuidv4() },
        { type: 'RADIO', value: 'No Longer Need', guid: uuidv4() },
        { type: 'RADIO', value: 'Missing Features', guid: uuidv4() },
        { type: 'RADIO', value: 'Technical Issues', guid: uuidv4() },
        { type: 'INPUT', value: 'Other', guid: uuidv4(), followup: { question: 'Could you tell us more?' } },
      ],
    },
  }),
};

export const defaultStepOrder = [STEP_TYPE.OFFER, STEP_TYPE.SURVEY, STEP_TYPE.FREEFORM, STEP_TYPE.CONFIRM];
export const defaultStepPlaceOrder = [STEP_PLACE.INITIAL_OFFER, STEP_PLACE.SURVEY, STEP_PLACE.FREEFORM, STEP_PLACE.FINAL_OFFER, STEP_PLACE.CONFIRM];

export const insertNewStep = (steps: BlueprintStep[], newStep: BlueprintStep, newStepPlace: StepPlace): BlueprintStep[] => {
  steps.forEach((s, i) => {
    // define step place for each step
    if (s.stepType === STEP_TYPE.OFFER) {
      if (i === 0) {
        s.stepPlace = STEP_PLACE.INITIAL_OFFER;
      } else {
        s.stepPlace = STEP_PLACE.FINAL_OFFER;
      }
    } else {
      s.stepPlace = s.stepType as StepPlace;
    }
  });
  newStep.stepPlace = newStepPlace;
  const newSteps = [...steps, newStep];
  newSteps.sort((a, b) => defaultStepPlaceOrder.indexOf(a.stepPlace as StepPlace) - defaultStepPlaceOrder.indexOf(b.stepPlace as StepPlace));
  return newSteps;
};

// eslint-disable-next-line arrow-body-style
export const createBaseStep = (type: StepType, options: { offerType?: OfferType }) => {
  return BASE_STEPS[type](options);
};

export const DISCOUNT_TYPE: { [key: string]: DiscountType } = {
  AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT',
};

export const paddleCouponIdToCoupon = (couponId: string) => {
  const [customAmount, customDuration] = couponId.split('@');
  const couponAmount = parseInt(customAmount, 10);
  const couponDuration = customDuration === 'ONCE' ? 1 : null;
  if (!(customAmount && customDuration)) {
    return null;
  }
  const normalCoupon = {
    id: couponId,
    couponAmount,
    couponType: DISCOUNT_TYPE.AMOUNT,
    couponDuration,
  };
  return normalCoupon;
};

export const discountCouponToString = (coupon: IDiscountCoupon): string => {
  let duration = 'forever';
  if (coupon.couponDuration) {
    duration = coupon.couponDuration === 1 ? 'one time' : `for ${coupon.couponDuration} months`;
  }
  let amount;
  if (coupon.couponType === DISCOUNT_TYPE.PERCENT) {
    amount = `${coupon.couponAmount}% off`;
  } else if (coupon.couponType === DISCOUNT_TYPE.AMOUNT) {
    const divisor = getCurrencyDivisor(coupon.currency);
    amount = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: coupon.currency || 'USD',
      currencyDisplay: 'narrowSymbol',
    }).format((coupon.couponAmount || 0) / divisor);
    // trim off currency trailing decimal zeros
    amount = amount.replace(/\.00$/, '');
    amount += ` off`;
  }
  return `${amount} ${duration}`;
};

// for ICoupon instead of IDiscountCoupon
export const couponToString = (coupon: ICoupon): string => {
  let duration = 'forever';
  if (coupon.duration === 'once') {
    duration = 'once';
  } else if (coupon.duration === 'repeating') {
    duration = `for ${coupon.months} months`;
  }
  let amount;
  if (coupon.discountType === DISCOUNT_TYPE.PERCENT) {
    amount = `${coupon.percentOff}% off`;
  } else if (coupon.discountType === DISCOUNT_TYPE.AMOUNT) {
    const divisor = getCurrencyDivisor(coupon.currency);
    amount = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: coupon.currency || 'USD',
      currencyDisplay: 'narrowSymbol',
    }).format((coupon.amountOff || 0) / divisor);
    // trim off currency trailing decimal zeros
    amount = amount.replace(/\.00$/, '');
    amount += ` off`;
  }
  return `${amount} ${duration}`;
};

export const sessionOfferToString = (offer: SessionOffer): string => {
  if (isISessionDiscountOffer(offer)) {
    const normalCoupon = {
      id: offer.couponId,
      couponAmount: offer.couponAmount,
      couponType: offer.couponType,
      couponDuration: offer.couponDuration,
    };
    return discountCouponToString(normalCoupon);
  }
  if (isISessionPauseOffer(offer)) {
    return 'Pause';
  }
  if (isISessionTrialExtensionOffer(offer)) {
    return 'Trial Extension';
  }
  if (isISessionNewPlanOffer(offer)) {
    return 'Changed Plan';
  }
  if (isISessionContactOffer(offer)) {
    return 'Contact';
  }
  if (isISessionRedirectOffer(offer)) {
    return 'Redirect';
  }
  return '';
};

export const SESSION_RESULT = {
  ABORT: 'abort',
  CANCEL: 'cancel',
  PAUSE: 'pause',
  DISCOUNT: 'discount',
};

export const getSessionResult = (session: ISession) => {
  if (session.aborted) {
    return SESSION_RESULT.ABORT;
  }
  if (session.canceled) {
    return SESSION_RESULT.CANCEL;
  }
  if (session.acceptedOffer) {
    const { offerType } = session.acceptedOffer;
    if (offerType === OfferType.PAUSE) {
      return SESSION_RESULT.PAUSE;
    }
    if (offerType === OfferType.DISCOUNT) {
      return SESSION_RESULT.DISCOUNT;
    }
    return offerType.toLowerCase();
  }
  return null;
};

export interface IPopulatedSession extends ISession {
  segment?: ISegment;
}

interface IFlatSession {
  sessionId: string;
  sessionDate: Date;
  sessionResult?: string;
  bounced?: boolean;
  surveyResponse?: string;
  feedback?: string;
  followupResponse?: string;
  followupQuestion?: string;
  customerId?: string;
  customerEmail?: string;
  customerPlan?: string;
  customerTrialing?: boolean;
  customerSubscriptionStart?: Date;
  customerSegmentId?: string;
  customerSegmentName?: string;
  acceptedPauseDuration?: number;
  acceptedCouponId?: string;
  acceptedCouponType?: string;
  acceptedCouponAmount?: number;
  acceptedCouponDuration?: number;
}

export const flattenSession = (session: IPopulatedSession): IFlatSession => {
  const sessionResult = getSessionResult(session) || '';
  let acceptedOfferDetails;
  if (session.acceptedOffer) {
    if (isISessionDiscountOffer(session.acceptedOffer)) {
      acceptedOfferDetails = {
        acceptedCouponId: session.acceptedOffer.couponId,
        acceptedCouponType: session.acceptedOffer.couponType,
        acceptedCouponAmount: session.acceptedOffer.couponAmount,
        acceptedCouponDuration: session.acceptedOffer.couponDuration,
      };
    } else if (isISessionPauseOffer(session.acceptedOffer)) {
      acceptedOfferDetails = {
        acceptedPauseDuration: session.acceptedOffer.pauseDuration,
      };
    } else if (isISessionTrialExtensionOffer(session.acceptedOffer)) {
      acceptedOfferDetails = {
        trialExtensionDays: session.acceptedOffer.trialExtensionDays,
      };
    } else if (isISessionNewPlanOffer(session.acceptedOffer)) {
      acceptedOfferDetails = {
        newPlanId: session.acceptedOffer.newPlanId,
      };
    }
  }
  let customer;
  if (session.customer) {
    customer = {
      customerId: session.customer.id,
      customerEmail: session.customer.email,
      customerPlan: session.customer.planId,
      customerTrialing: !!session.customer.onTrial,
      customerSubscriptionStart: session.customer.subscriptionStart,
    };
  }
  const res: IFlatSession = {
    sessionId: session._id,
    sessionDate: session.createdAt,
    sessionResult,
    bounced: session.bounced,
    ...(session.surveyChoiceValue && { surveyResponse: session.surveyChoiceValue }),
    ...(session.feedback && { feedback: session.feedback }),
    ...(session.followupResponse && { followupResponse: session.followupResponse }),
    ...(session.followupQuestion && { followupQuestion: session.followupQuestion }),
    ...customer,
    ...(session.segment?.name && { customerSegmentName: session.segment.name }),
    ...(session.segment?._id && { customerSegmentId: session.segment._id }),
    ...acceptedOfferDetails,
  };

  return res;
};

export interface IFlatCampaign {
  campaignId: string;
  created: Date;
  customerId: string;
  email: string;
  active: boolean;
  amountFormatted: string;
  invoiceDate: string;
  amountRecoveredFormatted: string;
  attempts?: number;
  recovered: boolean;
  recoveredViaChurnkeyAutoRetry: boolean;
  recoveredViaEmail: boolean;
  recoveredViaSMS: boolean;
  recoveredViaFailedPaymentWall: boolean;
  recoveredViaOther: boolean;
  recoverDate?: Date;
  amountRecoveredViaChurnkeyAutoRetry?: string;
  amountRecoveredViaEmail?: string;
  amountRecoveredViaSMS?: string;
  amountRecoveredViaFailedPaymentWall?: string;
  amountRecoveredViaOther?: string;
  emailsSent: number;
  emailsOpened: number;
  emailsClicked: number;
  emailsBounced: number;
  onTrial?: boolean;
}

export const flattenCampaign = (campaign: ICampaign): IFlatCampaign => {
  const emails: IEmail[] = campaign.emails || [];
  const emailsSent = emails.filter((e) => e.isSent).length;
  const emailsOpened = emails.filter((e) => e.isOpened).length;
  const emailsClicked = emails.filter((e) => e.isClicked).length;
  const emailsBounced = emails.filter((e) => e.isBounced).length;
  const recoveredOther =
    !!campaign.recoverDate === true &&
    !!campaign.recoveredViaAutoRetry === false &&
    !!campaign.recoveredViaEmail === false &&
    !!campaign.recoveredViaSMS === false &&
    !!campaign.recoveredViaFailedPaymentWall === false
      ? true
      : false;

  let amountFormatted = '';
  let amountRecoveredFormatted = '';
  if (campaign.failedPayment?.invoice?.currency) {
    amountFormatted = currencyFormatWithDivisor(campaign.failedPayment?.amount, campaign.failedPayment.invoice.currency);
    amountRecoveredFormatted = currencyFormatWithDivisor(campaign.amountRecovered || 0, campaign.failedPayment.invoice.currency);
  }
  const res: IFlatCampaign = {
    campaignId: campaign._id,
    created: campaign.created,
    customerId: campaign.customerId,
    email: campaign.customerEmail,
    active: campaign.isActive,
    invoiceDate: campaign.invoice.created,
    recovered: !!campaign.recoverDate,
    recoverDate: campaign.recoverDate,
    recoveredViaChurnkeyAutoRetry: !!campaign.recoveredViaAutoRetry,
    recoveredViaEmail: !!campaign.recoveredViaEmail,
    recoveredViaSMS: !!campaign.recoveredViaSMS,
    recoveredViaFailedPaymentWall: !!campaign.recoveredViaFailedPaymentWall,
    recoveredViaOther: Boolean(recoveredOther),

    amountFormatted,
    amountRecoveredFormatted,

    amountRecoveredViaChurnkeyAutoRetry: !!campaign.recoverDate && !!campaign.recoveredViaAutoRetry ? amountRecoveredFormatted : '0',
    amountRecoveredViaEmail: !!campaign.recoverDate && !!campaign.recoveredViaEmail ? amountRecoveredFormatted : '0',
    amountRecoveredViaSMS: !!campaign.recoverDate && !!campaign.recoveredViaSMS ? amountRecoveredFormatted : '0',
    amountRecoveredViaFailedPaymentWall: !!campaign.recoverDate && !!campaign.recoveredViaFailedPaymentWall ? amountRecoveredFormatted : '0',
    amountRecoveredViaOther: recoveredOther ? amountRecoveredFormatted : '0',

    attempts: campaign.failedPayment?.allAttempts?.length,
    emailsSent,
    emailsOpened,
    emailsClicked,
    emailsBounced,
    onTrial: campaign.failedAfterTrial,
  };

  return res;
};

export const segmentAttributeLabels = {
  [SEGMENT_ATTRIBUTE.CUSTOMER_EMAIL]: 'Customer Email',
  [SEGMENT_ATTRIBUTE.PLAN_ID]: 'Plan',
  [SEGMENT_ATTRIBUTE.PRODUCT_ID]: 'Product',
  [SEGMENT_ATTRIBUTE.CURRENCY]: 'Subscription Currency',
  [SEGMENT_ATTRIBUTE.PRICE]: 'Subscription Price',
  [SEGMENT_ATTRIBUTE.BILLING_INTERVAL]: 'Billing Interval',
  [SEGMENT_ATTRIBUTE.BILLING_INTERVAL_COUNT]: 'Billing Interval Count',
  [SEGMENT_ATTRIBUTE.SUBSCRIPTION_AGE_MONTHS]: 'Subscription Age (months)',
  [SEGMENT_ATTRIBUTE.SUBSCRIPTION_START_DATE]: 'Subscription Start Date',
  [SEGMENT_ATTRIBUTE.SUBSCRIPTION_STATUS]: 'Subscription Status',
  [SEGMENT_ATTRIBUTE.SUBSCRIPTION_STATUS_ON_CANCEL]: 'Active/Trialing/Expired State on Cancellation',
  [SEGMENT_ATTRIBUTE.SUBSCRIPTION_DISCOUNT]: 'Subscription Discount',
  [SEGMENT_ATTRIBUTE.CUSTOMER_COUNTRY]: 'Customer Country',
  [SEGMENT_ATTRIBUTE.CUSTOMER_HAS_EMAIL]: 'Customer Has Email',
  [SEGMENT_ATTRIBUTE.CUSTOMER_HAS_PHONE]: 'Customer Has Phone',
  [SEGMENT_ATTRIBUTE.INVOICE_CURRENCY]: 'Invoice Currency',
  [SEGMENT_ATTRIBUTE.INVOICE_AMOUNT_DUE]: 'Invoice Amount Due',
  [SEGMENT_ATTRIBUTE.SURVEY_CHOICE]: 'Cancellation Survey Choice',
  [SEGMENT_ATTRIBUTE.CANCEL_TYPE]: 'Voluntary/Involuntary Cancellation',
  [SEGMENT_ATTRIBUTE.CANCEL_DATE]: 'Cancellation Date',
  [SEGMENT_ATTRIBUTE.FREEFORM_SENTIMENT]: 'Customer Sentiment on Cancellation Feedback',
};

export const segmentOperandLabels = {
  [SEGMENT_OPERAND.EQUAL]: 'is',
  [SEGMENT_OPERAND.INCLUDES]: 'is',
  [SEGMENT_OPERAND.GT]: 'greater than',
  [SEGMENT_OPERAND.LT]: 'less than',
  [SEGMENT_OPERAND.GTE]: 'greater than or equal to',
  [SEGMENT_OPERAND.LTE]: 'less than or equal to',
  [SEGMENT_OPERAND.BETWEEN]: 'between (inclusive)',
  [SEGMENT_OPERAND.NOT_EQUAL]: 'is not',
  [SEGMENT_OPERAND.NOT_INCLUDES]: 'is not',
  [SEGMENT_OPERAND.NOT_BETWEEN]: 'is not between',
};

export const segmentDateOperandLabels = {
  [SEGMENT_OPERAND.EQUAL]: 'is',
  [SEGMENT_OPERAND.INCLUDES]: 'is',
  [SEGMENT_OPERAND.GT]: 'after',
  [SEGMENT_OPERAND.LT]: 'before',
  [SEGMENT_OPERAND.GTE]: 'on or after',
  [SEGMENT_OPERAND.LTE]: 'on or before',
  [SEGMENT_OPERAND.BETWEEN]: 'between (inclusive)',
  [SEGMENT_OPERAND.NOT_EQUAL]: 'is not',
  [SEGMENT_OPERAND.NOT_INCLUDES]: 'is not',
  [SEGMENT_OPERAND.NOT_BETWEEN]: 'is not between',
};

export function getDefaultFilterValue(
  attributeType: CustomAttributeType,
  // attribute: SegmentAttribute | undefined | string,
  operand: SegmentOperand,
  previousValue?: SegmentFilterValue,
  previousOperand?: SegmentOperand
): SegmentFilterValue {
  let newFilterValue: SegmentFilterValue = [];
  let defaultValue = '' as any;
  if (attributeType === CUSTOM_ATTRIBUTE_TYPE.NUMBER) {
    defaultValue = 0;
  }
  if (attributeType === CUSTOM_ATTRIBUTE_TYPE.BOOLEAN) {
    defaultValue = false;
  }
  if (attributeType === CUSTOM_ATTRIBUTE_TYPE.DATE) {
    // current date formatted as 2023-12-25
    defaultValue = new Date().toISOString();
  }
  if ([SEGMENT_OPERAND.INCLUDES, SEGMENT_OPERAND.NOT_INCLUDES].includes(operand)) {
    newFilterValue = [];
    if (previousValue && previousValue.length) {
      newFilterValue = previousValue;
    } else {
      newFilterValue = [];
    }
  } else if ([SEGMENT_OPERAND.BETWEEN, SEGMENT_OPERAND.NOT_BETWEEN].includes(operand)) {
    if (previousValue && previousValue.length === 2) {
      newFilterValue = previousValue;
    } else if (previousValue && previousValue.length === 1) {
      if (previousOperand && [SEGMENT_OPERAND.GT, SEGMENT_OPERAND.GTE].includes(previousOperand)) {
        if (previousValue[0]) {
          newFilterValue = [previousValue[0], previousValue[0]];
        } else {
          newFilterValue = [defaultValue, defaultValue];
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (previousValue[0]) {
          newFilterValue = [defaultValue, previousValue[0]];
        } else {
          newFilterValue = [defaultValue, defaultValue];
        }
      }
    } else {
      newFilterValue = [defaultValue, defaultValue];
    }
  } else if ([SEGMENT_OPERAND.GT, SEGMENT_OPERAND.GTE, SEGMENT_OPERAND.LT, SEGMENT_OPERAND.LTE].includes(operand)) {
    if (previousValue && previousValue.length === 2) {
      if ([SEGMENT_OPERAND.GT, SEGMENT_OPERAND.GTE].includes(operand)) {
        newFilterValue = previousValue.slice(0, 1);
      } else {
        newFilterValue = previousValue.slice(1, 2);
      }
    } else if (previousValue && previousValue.length === 1) {
      newFilterValue = previousValue;
    } else {
      newFilterValue = [defaultValue];
    }
  }
  return newFilterValue;
}

// export function getCustomAttributeDefaultValue(attributeType: CustomAttributeType): SegmentFilterValue {
//   switch (attributeType) {
//     case CUSTOM_ATTRIBUTE_TYPE.STRING:
//       return [];
//     case CUSTOM_ATTRIBUTE_TYPE.NUMBER:
//       return [null];
//     case CUSTOM_ATTRIBUTE_TYPE.BOOLEAN:
//       return [null];
//     case CUSTOM_ATTRIBUTE_TYPE.DATE:
//       return ['', ''];
//     default:
//       return [];
//   }
// }

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const PAYMENT_PROVIDERS = {
  STRIPE: 'STRIPE',
  BRAINTREE: 'BRAINTREE',
  CHARGEBEE: 'CHARGEBEE',
  PADDLE: 'PADDLE',
};

export const COUPON_TYPE = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
};

export const BRAINTREE_PAUSE_DISCOUNT_ID = 'CHURNKEY_PAUSE';

export const CHARGEBEE_WRONG_VERSION_ERROR_CODE = 'configuration_incompatible';

export const PROVIDERS_WITH_IMMEDIATE_CANCEL = ['stripe', 'chargebee', 'paddle_billing']; // Cancel immediately instead of at period end

export const PADDLE_VERSION: { [key: string]: PaddleVersion } = {
  CLASSIC: 'CLASSIC',
  BILLING: 'BILLING',
};
export const PADDLE_VERSION_LIST = [PADDLE_VERSION.CLASSIC, PADDLE_VERSION.BILLING];
export const PADDLE_VERSION_OPTIONS = [
  {
    value: PADDLE_VERSION.CLASSIC,
    label: 'Paddle Classic',
  },
  {
    value: PADDLE_VERSION.BILLING,
    label: 'Paddle Billing',
  },
];

export const romanize = (original: number): string => {
  if (original < 1 || original > 3999) {
    throw new Error('Error: Input integer limited to 1 through 3,999');
  }

  const numerals = [
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
    ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
    ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
    ['M', 'MM', 'MMM'], // 1000-3000
  ];

  // TODO: Could expand to support fractions, simply rounding for now
  const digits = Math.round(original).toString().split('');
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      // eslint-disable-next-line no-param-reassign
      roman += numerals[position][parseInt(digit) - 1];
    }

    position -= 1;

    return roman;
  }, '');
};

export const DEFAULT_DNS_TARGET = '37.16.25.177';

export const DUNNING_DEFAULT_CTA_TEXT = 'Update Your Card Now';
export const DUNNING_DEFAULT_CTA_CODE = `[[${DUNNING_DEFAULT_CTA_TEXT}]]`;
export const DUNNING_DEFAULT_CTA_TAG = `<cta text="${DUNNING_DEFAULT_CTA_TEXT}" open="false">${DUNNING_DEFAULT_CTA_CODE}</cta>`;

export const REACTIVATION_DEFAULT_CTA_TEXT = 'Enter Your Payment Information Now';
export const REACTIVATION_DEFAULT_CTA_CODE = `[[${REACTIVATION_DEFAULT_CTA_TEXT}]]`;
export const REACTIVATION_DEFAULT_CTA_TAG = `<cta text="${REACTIVATION_DEFAULT_CTA_TEXT}" open="false">${REACTIVATION_DEFAULT_CTA_CODE}</cta>`;

export const SMS_MAGIC_LINK_TAG = '<magic-link>[[MAGIC LINK]]</magic-link>';

export const DUNNING_DEFAULT_SEND_TIME = '09:30';
export const REACTIVATION_DEFAULT_SEND_TIME = '09:30';

export function healthScoreToLetterGrade(grade: number): string {
  if (grade >= 9) {
    return 'A+';
  }
  if (grade >= 8.25) {
    return 'A';
  }
  if (grade >= 7.5) {
    return 'A-';
  }
  if (grade >= 7) {
    return 'B+';
  }
  if (grade >= 6.5) {
    return 'B';
  }
  if (grade >= 6) {
    return 'B-';
  }
  if (grade >= 5.25) {
    return 'C+';
  }
  if (grade >= 3.5) {
    return 'C';
  }
  if (grade >= 2.5) {
    return 'D';
  }
  return 'F';
}

// { color: '#D7354A', percentage: 40 },
// { color: '#F9D118', percentage: 60 },
// { color: '#309D82', percentage: 80 },
// { color: '#008765', percentage: 90 },

export const healthCategoryColor = {
  low: '#008765',
  medium: '#F9D118',
  high: '#D7354A',
};

export const riskGroups = [
  { label: 'Low Risk', key: 'low', color: '#52A475', icon: '🏝️', bgColor: '#D9F9E6', textColor: '#163523' },
  { label: 'Medium Risk', key: 'medium', color: '#B06FD3', icon: '🚸', bgColor: '#F2E7F8', textColor: '#431646' },
  { label: 'High Risk', key: 'high', color: '#EE7963', icon: '🏜️', bgColor: '#FAE7E2', textColor: '#461A11' },
];

export const dunningCampaignOutcomeMap = {
  recoveredViaEmail: 'Recovered via Churnkey Emails',
  recoveredViaAutoRetry: 'Recovered via Auto Retries',
  recoveredViaRetryStrategy: 'Recovered via Churnkey Precision Retries',
  recoveredOther: 'Recovered (Other)',
  recoveredViaFailedPaymentWall: 'Recovered via Failed Payment Wall',
};

export const reactivationCampaignOutcomeMap = {
  recoveredViaEmail: 'Reactivated via Churnkey Emails',
};

const defaultMerges = {
  FIRST_NAME: 'Elizabeth',
  CARD_BRAND: 'VISA',
  LAST_4: '4242',
} as Record<string, string>;

export const truncate = (text: string, stop: number, clamp?: string) => text.slice(0, stop) + (stop < text.length ? clamp || '…' : '');

export const ensureCta = (text: string): string => {
  if (!text.match(/\[\[(.*?)\]\]/g)) {
    return `${text}<br /><br />${DUNNING_DEFAULT_CTA_TAG}`; // tiptap tag to inject into email for preview
  }
  return text;
};

export const replaceCta = (text: string, ctaLink?: string, required?: boolean, isSms = false): string => {
  let replacedText = text;

  // If there is no CTA, add one at the end
  if (!replacedText.match(/\[\[(.*?)\]\]/g) && required) {
    replacedText += `<br /><br />${DUNNING_DEFAULT_CTA_CODE}`;
  }

  // Replace CTA
  const ctaRegex = /\[\[\s*(.+?)\s*\]\]/g;
  const prefix = ctaLink ? `<a href="${ctaLink}" target="_blank" class="text-blue-500 hover:underline">` : '';
  const suffix = ctaLink ? '</a>' : '';
  replacedText = replacedText.replace(ctaRegex, (match, buttonText) => {
    if (isSms) {
      return `\n<a href="${ctaLink}" target="_blank" class="text-blue-500 hover:underline">${ctaLink}</a>\n`;
    }
    return `${prefix}<button type="button" class="el-button my-3 mx-auto block content-center el-button--primary"><span>${buttonText}</span></button>${suffix}`;
  });

  return replacedText;
};

export const replaceMergeFields = (
  text: string,
  mergeFieldValues: Record<string, string> = {},
  requiresCta?: boolean,
  ctaLink?: string,
  isSms = false
): string => {
  if (!text) return '';
  const combinedMergeFields = { ...defaultMerges, ...mergeFieldValues };

  // Replace merge fields
  const regex = /\{\{\s*(\w+)\s*(?:\|\s*([^}]*)\s*)?\}\}/g;
  let replacedText = text.replace(regex, (match, mergeField, fallback) => {
    return combinedMergeFields && combinedMergeFields[mergeField] !== undefined ? combinedMergeFields[mergeField] : fallback || mergeField;
  });

  replacedText = replaceCta(replacedText, ctaLink, requiresCta, isSms);

  return replacedText;
};

export const removeHTMLTags = (text: string): string => {
  if (!text) return '';
  return text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
};

Vue.filter(
  'formatHtmlMerge',
  (text: string, length = 90, mergeFieldValues: Record<string, string> = {}, requiresCta?: boolean, ctaLink?: string, isSms = false) => {
    return truncate(removeHTMLTags(replaceMergeFields(text, mergeFieldValues, requiresCta, ctaLink, isSms)), length);
  }
);

Vue.filter(
  'formatHtmlMergeMultiline',
  (text: string, length = 90, mergeFieldValues: Record<string, string> = {}, requiresCta?: boolean, ctaLink?: string) => {
    const replaceAndTruncate = (text: string, length: number): string => {
      const replacedText = replaceMergeFields(text, mergeFieldValues, requiresCta, ctaLink);
      const plainText = replacedText
        .replace(/<p[^>]*>/g, '\n')
        .replace(/<\/p>/g, '')
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, ' ');
      const truncatedText = plainText.length > length ? plainText.substring(0, length) + '...' : plainText;
      return truncatedText;
    };

    return replaceAndTruncate(text, length);
  }
);
