/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
import { v4 as uuidv4 } from 'uuid';
import { BlueprintStep, ICampaignBlueprint, IEmailBlueprint, ISmsBlueprint,IDunningMessageGroup, CampaignSchedule } from '@/store/types';
import { createBaseOffer, PAUSE_INTERVAL, STEP_TYPE, DUNNING_DEFAULT_CTA_TAG, CAMPAIGN_SCHEDULE, SMS_MAGIC_LINK_TAG } from '@/store/shared';
import { OfferType } from '@/models/Offer';
import { getNextAvailableHalfHourSlot } from './date-utils';

// copy an object but create new guids wherever they exist
function deepCopy(obj: any): any {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};

    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) {
        if (attr === 'guid') {
          copy[attr] = uuidv4();
        } else {
          copy[attr] = deepCopy(obj[attr]);
        }
      }
    }
    return copy;
  }

  throw new Error("Unable to copy object - Its type isn't supported.");
}

export const duplicateSteps = (originalSteps: BlueprintStep[]): BlueprintStep[] => {
  return originalSteps.map(deepCopy);
};

export const generateAIReferenceSteps = (): BlueprintStep[] => {
  const pauseOfferHeader = 'How about a billing break?';
  const pauseOfferDescription = `You can pause your account instead of cancelling.`;
  return [
    // PAUSE OFFER
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.OFFER,
      enabled: true,
      header: pauseOfferHeader,
      description: pauseOfferDescription,
      offer: {
        header: pauseOfferHeader,
        description: pauseOfferDescription,
        guid: uuidv4(),
        offerType: OfferType.PAUSE,
        pauseConfig: {
          maxPauseLength: 3,
          pauseInterval: PAUSE_INTERVAL.MONTH,
        },
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.SURVEY,
      enabled: true,
      header: `We're sorry to see you go.`,
      description: `Can you tell us why you're leaving?`,
      survey: {
        guid: uuidv4(),
        choices: [
          {
            type: 'RADIO',
            value: 'Budget',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'The next one is on us.',
              description: 'We understand budgets might be tight right now.',
            },
          },
          {
            type: 'RADIO',
            value: 'Not Needed Right Now',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'Maybe we can work something out.',
              description: `You're eligible for a discount.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Missing Features',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.CONTACT),
              header: `Got a minute?`,
              description: `We'd love to see if there's a way we can provide what you need.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Technical Issues',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `Can we make things right?`,
              description: `Sounds like we could have done better. This might help.`,
            },
          },
          { type: 'INPUT', value: 'Other', guid: uuidv4(), followup: { question: 'Could you tell us more?' } },
        ],
      },
    },
    // FREEFORM FEEDBACK
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.FREEFORM,
      enabled: true,
      header: 'What could we have done better?',
      description: `We want to make the best product possible. We'd appreciate your honest feedback.`,
      freeformConfig: {
        inputRequired: false,
        minLength: 40,
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.CONFIRM,
      enabled: true,
      header: 'Just making sure.',
      description: '<p>You would lose access to all our amazing features!</p><p>Are you sure you want to cancel?</p>',
      confirmConfig: {
        requireAcknowledgement: true,
        discountNotice: true,
      },
    },
  ];
};

const generateBasicSteps = (): BlueprintStep[] => {
  const pauseOfferHeader = 'How about a billing break?';
  const pauseOfferDescription = `You can pause your account instead of cancelling.`;
  return [
    // PAUSE OFFER
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.OFFER,
      enabled: true,
      header: pauseOfferHeader,
      description: pauseOfferDescription,
      offer: {
        header: pauseOfferHeader,
        description: pauseOfferDescription,
        guid: uuidv4(),
        offerType: OfferType.PAUSE,
        pauseConfig: {
          maxPauseLength: 3,
          pauseInterval: PAUSE_INTERVAL.MONTH,
        },
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.SURVEY,
      enabled: true,
      header: `We're sorry to see you go.`,
      description: `Can you tell us why you're leaving?`,
      survey: {
        guid: uuidv4(),
        choices: [
          {
            type: 'RADIO',
            value: 'Budget',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'The next one is on us.',
              description: 'We understand budgets might be tight right now.',
            },
          },
          {
            type: 'RADIO',
            value: 'Not Needed Right Now',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'Maybe we can work something out.',
              description: `You're eligible for a discount.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Missing Features',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.CONTACT),
              header: `Got a minute?`,
              description: `We'd love to see if there's a way we can provide what you need.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Technical Issues',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `Can we make things right?`,
              description: `Sounds like we could have done better. This might help.`,
            },
          },
          { type: 'INPUT', value: 'Other', guid: uuidv4(), followup: { question: 'Could you tell us more?' } },
        ],
      },
    },
    // FREEFORM FEEDBACK
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.FREEFORM,
      enabled: true,
      header: 'What could we have done better?',
      description: `We want to make the best product possible. We'd appreciate your honest feedback.`,
      freeformConfig: {
        inputRequired: false,
        minLength: 40,
      },
    },
  ];
};

const mapAiSurveyChoice = (option: any): any => {
  // Sometimes there's an artifact, a broken divider: ##
  const offerType: OfferType = option.offerType.replace(/#/g, '') as OfferType;
  const choice = {
    type: 'RADIO',
    value: option.optionText,
    guid: uuidv4(),
    offer: {
      ...createBaseOffer(offerType),
      header: option.offerHeader,
      description: option.offerDesc,
    },
  };
  switch (choice.offer.offerType) {
    case OfferType.DISCOUNT:
      choice.offer.discountConfig = {
        couponId: option.offerSettings,
      };
      break;
    case OfferType.REDIRECT:
      choice.offer.redirectConfig = {
        redirectUrl: option.offerSettings,
        redirectLabel: 'Go to Website', // TODO: generate with AI, too
      };
      break;
    case OfferType.PAUSE:
      choice.offer.pauseConfig = {
        maxPauseLength: parseInt(option.offerSettings, 10),
        pauseInterval: PAUSE_INTERVAL.MONTH,
      };
      break;
  }
  return choice;
};

const generateB2BSteps = (): BlueprintStep[] => {
  const pauseOfferHeader = `Let's hit pause.`;
  const pauseOfferDescription = `Schedule a subscription pause instead of cancelling. Preserve your data, settings, and come back when you're ready.`;
  return [
    // PAUSE OFFER
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.OFFER,
      enabled: true,
      header: pauseOfferHeader,
      description: pauseOfferDescription,
      offer: {
        header: pauseOfferHeader,
        description: pauseOfferDescription,
        guid: uuidv4(),
        offerType: OfferType.PAUSE,
        pauseConfig: {
          maxPauseLength: 3,
          pauseInterval: PAUSE_INTERVAL.MONTH,
        },
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.SURVEY,
      enabled: true,
      header: `We're sorry you're thinking about making a change.`,
      description: `Can you help us understand why?`,
      survey: {
        guid: uuidv4(),
        choices: [
          {
            type: 'RADIO',
            value: 'Budget',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'The next month. On us.',
              description: 'We understand budgets might be tight, and we want to keep adding value for you.',
            },
          },
          {
            type: 'RADIO',
            value: 'Not Needed Right Now',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `We're sure we can work something out.`,
              description: 'We might be able to be of value at a different price point.',
            },
          },
          {
            type: 'RADIO',
            value: 'Missing Features',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.CONTACT),
              header: `Time for a quick chat?`,
              description: `There might be something in our product roadmap that could solve this.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Technical Issues',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `Can we make things right?`,
              description: `Sounds like we could have done better. This might help.`,
            },
          },
          { type: 'INPUT', value: 'Other', guid: uuidv4(), followup: { question: 'Could you tell us more?' } },
        ],
      },
    },
    // FREEFORM FEEDBACK
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.FREEFORM,
      enabled: true,
      header: 'Anything we could have done better?',
      description: 'Your honest input is the best way we can improve our product.',
      freeformConfig: {
        inputRequired: false,
        minLength: 40,
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.CONFIRM,
      enabled: true,
      header: `You're all set. Here's what happens next.`,
      description: `Once your billing period expires, you'll lose access to your data, settings, and account history.`,
      confirmConfig: {
        requireAcknowledgement: true,
        discountNotice: false,
      },
    },
  ];
};

const generateMergeFieldsSteps = (): BlueprintStep[] => {
  const mergeFields = {
    customerName: '<merge-field id="CUSTOMER_NAME" label="Customer Name" fallback="dear customer">{{CUSTOMER_NAME|dear customer}}</merge-field>',
  };
  const pauseOfferHeader = `Let's hit pause.`;
  const pauseOfferDescription = `Schedule a subscription pause instead of cancelling. Preserve your data, settings, and come back when you're ready.`;
  return [
    // PAUSE OFFER
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.OFFER,
      enabled: true,
      header: pauseOfferHeader,
      description: pauseOfferDescription,
      offer: {
        header: pauseOfferHeader,
        description: pauseOfferDescription,
        guid: uuidv4(),
        offerType: OfferType.PAUSE,
        pauseConfig: {
          maxPauseLength: 3,
          pauseInterval: PAUSE_INTERVAL.MONTH,
        },
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.SURVEY,
      enabled: true,
      header: `We're sorry you're thinking about making a change.`,
      description: `Can you help us understand why, ${mergeFields.customerName}?`,
      survey: {
        guid: uuidv4(),
        choices: [
          {
            type: 'RADIO',
            value: 'Budget',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: 'The next month. On us.',
              description: 'We understand budgets might be tight, and we want to keep adding value for you.',
            },
          },
          {
            type: 'RADIO',
            value: 'Not Needed Right Now',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `We're sure we can work something out.`,
              description: 'We might be able to be of value at a different price point.',
            },
          },
          {
            type: 'RADIO',
            value: 'Missing Features',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.CONTACT),
              header: `Time for a quick chat?`,
              description: `There might be something in our product roadmap that could solve this.`,
            },
          },
          {
            type: 'RADIO',
            value: 'Technical Issues',
            guid: uuidv4(),
            offer: {
              ...createBaseOffer(OfferType.DISCOUNT),
              header: `Can we make things right?`,
              description: `Sounds like we could have done better. This might help.`,
            },
          },
          { type: 'INPUT', value: 'Other', guid: uuidv4(), followup: { question: 'Could you tell us more?' } },
        ],
      },
    },
    // FREEFORM FEEDBACK
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.FREEFORM,
      enabled: true,
      header: 'Anything we could have done better?',
      description: 'Your honest input is the best way we can improve our product.',
      freeformConfig: {
        inputRequired: false,
        minLength: 40,
      },
    },
    {
      guid: uuidv4(),
      stepType: STEP_TYPE.CONFIRM,
      enabled: true,
      header: `You're all set. Here's what happens next.`,
      description: `Once your billing period expires, you'll lose access to your data, settings, and account history.`,
      confirmConfig: {
        requireAcknowledgement: true,
        discountNotice: false,
      },
    },
  ];
};

type TemplateVersion = 'BASIC' | 'B2B' | 'MERGEFIELDS';

export const generateTemplateSteps = (version: TemplateVersion = 'BASIC') => {
  if (version === 'B2B') {
    return generateB2BSteps();
  }
  if (version === 'MERGEFIELDS') {
    return generateMergeFieldsSteps();
  }
  return generateBasicSteps();
};

export const DEFAULT_SMS_CONTENT = `
<p>
Hey <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — 
</p>
<p>We tried to run your payment but it didn’t go through. If you’d like to keep your membership, please open the link below:</p>
<p>${SMS_MAGIC_LINK_TAG}</p>`;

export const generateDefaultSms = (email: IEmailBlueprint): ISmsBlueprint => {
  return {
    guid: email.guid,
    enabled: false, // SMS disabled by default
    content: DEFAULT_SMS_CONTENT,
    sendOnDay: email.sendOnDay,
  };
};

export const generateEmailBlueprint = ({
  from,
  replyTo,
  subject,
  previewText,
  content,
  senderName,
  sendOnDay,
  sendOnDate,
}: {
  from?: string;
  replyTo?: string;
  subject?: string;
  previewText?: string;
  content?: string;
  senderName?: string;
  sendOnDay?: number;
  sendOnDate?: Date;
}): IEmailBlueprint => {
  return {
    guid: uuidv4(),
    enabled: true,
    sendOnDay: sendOnDay || (sendOnDate ? undefined : 1),
    sendOnDate: sendOnDate || (sendOnDay ? undefined : new Date()),
    senderName: senderName || '',
    from: from || '',
    replyTo: replyTo || '',
    subject: subject || '',
    previewText: previewText || '',
    content: content || '',
  };
};

export const generateDunningMessage = ({
  baseMessage,
  sendOnDay,
}: {
  baseMessage: IDunningMessageGroup;
  sendOnDay: number;
}): IDunningMessageGroup => {
  // Clone base message
  const newGuid = uuidv4();
  const newMessage = {
    ...baseMessage,
    guid: newGuid,
    email: {
      ...baseMessage.email,
      sendOnDay,
      guid: newGuid,
    },
    sms: {
      ...baseMessage.sms,
      sendOnDay,
      guid: newGuid,
    },
  };
  // Set default SMS content if empty
  newMessage.sms.content = newMessage.sms.content ?? DEFAULT_SMS_CONTENT;
  // Delete _id from sms and email
  delete newMessage.sms._id;
  delete newMessage.email._id;
  // Return new message
  return newMessage;
};

export const generateDunningSequence = ({
  from,
  replyTo,
  company,
  senderName,
  segmented = false,
}: {
  from: string;
  replyTo: string;
  company: string;
  senderName: string;
  segmented: boolean;
}): Omit<ICampaignBlueprint, '_id'> => {
  const newCampaign: Omit<ICampaignBlueprint, '_id'> = {
    guid: uuidv4(),
    enabled: !segmented,
    audience: segmented ? { name: '', filters: [] } : undefined,
    publishedCopies: [],
    sms: [],
    emails: [
      {
        guid: uuidv4(),
        enabled: true,
        sendOnDay: 0,
        senderName,
        from, // lookup team member
        replyTo,
        subject: `Payment 💳 failure for ${company}.`,
        templateId: 'dunning', // defaults to a basic template
        previewText: `We're having troubling completing your most recent payment`,
        content: `
        Hey <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — 
        <br />
        <br />
        Just letting you know that we’re having trouble completing your most recent payment.
        <br />
        <br />
    👇  Mind updating the <merge-field id="CARD_BRAND" fallback="credit" label="Card Brand">{{CARD_BRAND|credit}}</merge-field> card on file (<merge-field id="LAST_4" label="Last 4 of Card" fallback="xxxx">{{LAST_4|xxxx}}</merge-field>)? We’d hate for you to lose out on your subscription!
        <br />
        <br />
        ${DUNNING_DEFAULT_CTA_TAG}
        `.replace(/^ +/g, ''),
      },
      {
        guid: uuidv4(),
        enabled: true,
        sendOnDay: 3,
        senderName,
        from, // lookup team member UserService.getMembers(orgId)
        replyTo,
        subject: `Need help with your ${company} subscription?`,
        previewText: `We're still unable to process the payment for your subscription`,
        templateId: 'dunning', // defaults to a basic template
        content: `
        Hey <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — 
        <br />
        <br />
        We’re still unable to process the payment for your subscription, and we’d hate for you to lose access.
        <br />
        <br />
        👇 Mind taking a second to update your card?
        <br />
        <br />
        ${DUNNING_DEFAULT_CTA_TAG}
        <br />
        <br />
        Thanks!
        <br />
        `.replace(/^ +/g, ''),
      },
      {
        guid: uuidv4(),
        enabled: true,
        sendOnDay: 7,
        senderName,
        from, // lookup team member UserService.getMembers(orgId)
        replyTo,
        subject: `🛟 3rd attempt: ${company} payment didn't work`,
        previewText: `We've made it simple to update your card without logging in`,
        templateId: 'dunning', // defaults to a basic template
        content: `
        Hey, it’s us again. Your payment didn’t go through and we made it so easy to update your card that you don’t even have to log in. Just click here to get it updated and keep your subscription. 
        <br />
        <br />
        ${DUNNING_DEFAULT_CTA_TAG}
        <br />
        <br />
        Got questions? Just reply and let us know.
        <br />

        `.replace(/^ +/g, ''),
      },
      {
        guid: uuidv4(),
        enabled: true,
        sendOnDay: 15,
        senderName,
        from, // lookup team member UserService.getMembers(orgId)
        replyTo,
        subject: `🙋 Last attempt: please update ${company} payment method`,
        previewText: `If you don't update your card information, your subscription will be canceled`,
        templateId: 'dunning', // defaults to a basic template
        content: `
        Hey <merge-field id="FIRST_NAME" fallback="again" label="First Name">{{FIRST_NAME|again}}</merge-field> — 
        <br />
        <br />
        We tried to run your payment for the fourth time and the charge didn’t go through. It’s simple to update your information, just go here 👇. If the next charge fails, we'll automatically cancel your account.
        <br />
        <br />
        ${DUNNING_DEFAULT_CTA_TAG}
        <br />
        <br />
        `.replace(/^ +/g, ''),
      },
      {
        guid: uuidv4(),
        enabled: true,
        sendOnDay: 28,
        senderName,
        from, // lookup team member UserService.getMembers(orgId)
        replyTo,
        subject: `🚨 Tomorrow your subscription will be cancelled!`,
        previewText: 'If you would like to keep your plan, please click the link in this email today!',
        templateId: 'dunning', // defaults to a basic template
        content: `
        Hey <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — 
        <br />
        <br />
        We tried to complete your ${company} subscription payment for a fifth time, but it didn’t go through. If you'd like to keep your membership in your plan, please click the link below. Thanks!
        <br />
        <br />
        ${DUNNING_DEFAULT_CTA_TAG}
        <br />
        <br />
        `.replace(/^ +/g, ''),
      },
    ],
  };

  // go through emails and generate corresponding sms:
  newCampaign.sms = newCampaign.emails.map(generateDefaultSms);

  return newCampaign;
};

export const generateReactivationSequence = ({
  from,
  replyTo,
  company,
  senderName,
  segmented = false,
  campaignSchedule = CAMPAIGN_SCHEDULE.RECURRING,
}: {
  from: string;
  replyTo: string;
  company: string;
  senderName: string;
  segmented: boolean;
  campaignSchedule: CampaignSchedule;
}): Omit<ICampaignBlueprint, '_id'> => {
  const ctaText = 'View Offer';
  const LINEBREAK = '<br /><br />';
  const GOODBYE = `${LINEBREAK}Thanks! Let me know if you have any questions.${LINEBREAK}${senderName}`;

  const generateRecurringEmails = () => [
    {
      guid: uuidv4(),
      enabled: true,
      sendOnDay: 45,
      senderName,
      from,
      replyTo,
      subject: `🟢 There's a 50% discount waiting for you at ${company}`,
      previewText: '',
      templateId: 'reactivation',
      content: `
      Hey, <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — since you left, we've made some updates that customers like you have really enjoyed. <em>{UPDATE ONE DOES THIS, UPDATE TWO DOES THAT}</em>.
      ${LINEBREAK}
      We know you'll love these updates. And to prove it, you can get <b>50% off for the next three months</b> just by hitting the button below.
      <cta text="${ctaText}">[[${ctaText}]]</cta>
      ${GOODBYE}
      `.replace(/^ +/g, ''),
    },
    {
      guid: uuidv4(),
      enabled: true,
      sendOnDay: 50,
      senderName,
      from,
      replyTo,
      subject: `🟡 Discount expiring soon on your ${company} account`,
      previewText: '',
      templateId: 'reactivation',
      content: `
      Hey, <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — your 50% discount for three months is expiring in a week, and I don't want you to miss it. All you have to do is hit the button below and we'll handle everything.
      <cta text="${ctaText}">[[${ctaText}]]</cta>
      As a reminder, we think you'll love the latest updates we've made. <em>{UPDATE ONE DOES THIS, UPDATE TWO DOES THAT}</em>.
      ${GOODBYE}
      `.replace(/^ +/g, ''),
    },
    {
      guid: uuidv4(),
      enabled: true,
      sendOnDay: 55,
      senderName,
      from,
      replyTo,
      subject: `🚨 Last chance to claim your ${company} discount`,
      previewText: '',
      templateId: 'reactivation',
      content: `
      Hey, <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — your 50% discount for three months is about to expire, and I don't want you to miss it. All you have to do is hit the button below and we'll handle everything.
      <cta text="${ctaText}">[[${ctaText}]]</cta>
      As a reminder, we think you'll love the latest updates we've made. <em>{UPDATE ONE DOES THIS, UPDATE TWO DOES THAT}</em>.
      ${GOODBYE}
      `.replace(/^ +/g, ''),
    },
  ];

  const sendOnDate = getNextAvailableHalfHourSlot();
  const timeToSend = sendOnDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

  const generateOneTimeEmails = () => [
    {
      guid: uuidv4(),
      enabled: true,
      sendOnDate,
      timeToSend,
      senderName,
      from,
      replyTo,
      subject: `🟢 There's a 50% discount waiting for you at ${company}`,
      previewText: '',
      templateId: 'reactivation',
      content: `
      Hey, <merge-field id="FIRST_NAME" fallback="there" label="First Name">{{FIRST_NAME|there}}</merge-field> — since you left, we've made some updates that customers like you have really enjoyed. <em>{UPDATE ONE DOES THIS, UPDATE TWO DOES THAT}</em>.
      ${LINEBREAK}
      We know you'll love these updates. And to prove it, you can get <b>50% off for the next three months</b> just by hitting the button below.
      <cta text="${ctaText}">[[${ctaText}]]</cta>
      ${GOODBYE}
      `.replace(/^ +/g, ''),
    },
  ];

  return {
    guid: uuidv4(),
    enabled: !segmented,
    audience: segmented ? { name: '', filters: [] } : undefined,
    publishedCopies: [],
    campaignSchedule,
    emails: campaignSchedule === CAMPAIGN_SCHEDULE.RECURRING ? generateRecurringEmails() : generateOneTimeEmails(),
  };
};
